<template>
  <div class="admin-pages">
    <section>
      <notifications group="foo" position="center right" />
      <b-modal v-model="showModalNoConnection" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog"
        aria-modal :can-cancel="false">
        <template #default="props">
          <NoConnection @close="props.close" />
        </template>
      </b-modal>
      <b-modal v-model="showModalReturnApp" width="915px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <ReturnApp :automatic="true" @close="props.close" :obj="data" />
        </template>
      </b-modal>

      <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
        :can-cancel="false">
        <template #default="props">
          <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :JsonObject="objData"
            :countDown="countDown" @ActionAfterLoadingDashboard="actionAfterLoadingDashboard
              " :route="route">
          </LoadingCMD>
        </template>
      </b-modal>

      <div id="dashComponent">
        <div style="padding-top: 10px;" />
        <Operator v-if="hasDatabase && showTab('Operator') && !errorS2S" />
        <Client v-if="hasDatabase && showTab('Client') && !errorS2S" />
        <ProgressiveGlobal v-if="hasDatabase && showTab('GlobalProgressive') && !errorS2S" />
        <Email v-if="hasDatabase && showTab('E-mail') && !errorS2S" />
        <Configurations :IsFromHeader="false" v-if="showTab('Configuration')" />
      </div>
      <QuickMenu v-if="opensTabs > 1" :key="opensTabs" :menu-count="opensTabs" :icon-class=icons :menu-url-list=list
        :background-color=backgroundColor :color=color :position=position @process=showComponent>
      </QuickMenu>
      <b-modal v-model="showSelectOperator" width="500px" max-width="500px" trap-focus :destroy-on-hide="true"
        aria-role="dialog" aria-modal :can-cancel="false">
        <template #default="props">
          <SearchFilter :fromDash="true" @close="props.close" :isOperator="true" :isConfig="false"
            :list="listOperatorFilter" :selected="operatorSelected"
            style="height: 500px; width: 500px; overflow-x: hidden !important; overflow-y: hidden !important;"
            @ActionAfterCMDSearchFilter="updateOperatorName" />
          <div v-if="false">
            <b-form-input list="listOperator" name="listConfig" id="listConfig" class="form-control"
              v-model="operatorSelected" />
            <datalist id="listOperator">
              <option v-for="operator in listOperatorFilter" v-bind:value="operator.name" :key="operator.name">
                {{ operator.name }}
              </option>
            </datalist>
          </div>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
import QuickMenu from "@/components/template/QuickMenu.vue";
import Operator from "../admin/Modules/Operator.vue";
import Client from "../admin/Modules/Client.vue";
import Email from "../admin/Modules/Email";
import Configurations from "../admin/Modules/Configurations";
import NoConnection from "@/components/connection/noConnection.vue";
import ProgressiveGlobal from "../admin/Modules/ProgressiveGlobal";
import notify from "@/config/notify.js";
import Socket from "@/config/webSocket.js";
import ReturnApp from "@/components/reset/ReturnApp.vue";
import SearchFilter from "@/components/dropdown/SearchFilter.vue";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import { classValue } from "@/mixins/enums.js";
import { mapState } from "vuex";
import { countries } from "@/mixins/countryCode.js";
import { clientCommands, webSocketStatus } from "@/js/ClientCommands.js";
import {
  showCursor,
  hideCursor,
} from "@/js/screenAdjustment";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";


export default {
  name: "AdminPages",
  mixins: [en, es, nor, swe],
  data() {
    return {
      opensTabs: 0,
      res: "",
      datas: [],
      selected: "",
      idTab: 0,
      nameTab: "",
      ableRefresh: false,
      intervalGetDatas: null,
      showModalReturnApp: false,
      data: {},
      showSelectOperator: false,
      listOperator: [],
      listOperatorFilter: [],
      operatorSelected: "",
      opName: "",
      tabsName: [
        "Session",
        "Pin",
        "Bonus",
        "Terminals",
        "Operator",
        "Client",
        "Progressives",
        "GlobalProgressive",
        "User",
        "E-mail",
        "License",
        "Panel",
        "Cashier",
        "ReportManager",
        "Digi",
        "Debug",
        "Configuration",
      ],
      countries: countries,
      cmd: "",
      countDown: 60,
      route: "",
      sendingCMDMessage: "",
      showLoadingCMD: false,

      objData: {},
      icons: ["icons-lg2 icons-white icons-group", "icons-lg2 icons-white icons-globe", "icons-lg2 icons-white icons-user", "icons-lg2 icons-white icons-at", "icons-lg2 icons-white icons-cog"],
      list: [{ 'isLink': false }, { 'isLink': true, url: "/doo" }, { 'isLink': true, url: "/foo" }, { 'isLink': false }, { 'isLink': false }],
      backgroundColor: '#ffb648',
      color: '#ffffff',
      position: 'top-left',
      isOpenNewTab: false
    };
  },
  computed: mapState([
    "session",
    "communication",
    "hasDatabase",
    "tabs",
    "user",
    "currentTab",
    "stopRequest",
    "showReportManagerOptions",
    "userLogged",
    "userLoggedTemp",
    "IP",
    "completeLogin",
    "serverDataObjectFilter",
    "lang",
    "locale",
    "errorS2S",
    "showModalNoConnection",
    "idEmailSender",
    "isElectronVar",
    "isTouchScreen",
    "isWindows",
    "isFBM",
    "dataModule",
    "operatorName",
    "token"
  ]),
  getCount() {
    return Number(this.opensTabs)
  },
  getIsOpenNewTab() {
    return Boolean(this.isOpenNewTab)
  }
  ,
  components: {
    Email,
    Configurations,
    NoConnection,
    ProgressiveGlobal,
    ReturnApp,
    SearchFilter,
    Operator,
    LoadingCMD,
    QuickMenu,
    Client
    /*
     */
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    showTab(name) {
      let hasTab = name == this.currentTab;
      if (this.userLogged != null && this.userLogged.ChangePassword) {
        if (name == "User") {
          hasTab = true;
        }
      }
      return hasTab;
    },
    updateData(res) {
      this.$store.commit("setUpdateData", res);
      this.listOperatorFilter = []
      this.dataModule.Operator.forEach(op => {
        const opFilter = {
          name: op
        }
        this.listOperatorFilter.push(opFilter)
      });

    },
    noConnection() {
      if (!this.showModalNoConnection) {
        this.$store.commit("changeShowModalNoConnection", true);
        const intervalRetryConnection = setInterval(() => {
          clearInterval(this.intervalGetDatas);
          if (!this.showModalNoConnection) {
            clearInterval(intervalRetryConnection);
          } else {
            //Socket.close();
            //Socket.startWebSocket(this.IP);
          }
        }, 15000);
      }
    },
    onConnection() {
      this.$store.commit("changeShowModalNoConnection", false);
    },

    retryConnection() {
      //this.showModalNoConnection = false;
    },

    changeHasEmailsToSend(data) {
      this.$store.commit("changeHasEmailToSend", data.Ok);
    },

    loginRequired() {
      console.log("login required >>>>>>>>>>");
      if (this.ableRefresh) {
        //refresh();
        this.$router.push({ name: "App" });
      }
      this.ableRefresh = true;
    },
    forcedDisconnection(data) {
      if (data != undefined) {
        this.showModalReturnApp = true;
        this.data.CMD = clientCommands.ForcedDisconnection.name;
        this.data = data;
      }
    },
    setUpdateConfigs(data) {
      this.$store.commit("setUpdateConfigs", data.Object);
    },
    setCloseSession(data) {
      if (data.Ok) {
        this.$store.commit("changeSession", false);
      }
    },
    setOpenSession(data) {
      if (data.Ok) {
        this.$store.commit("changeSession", true);
        this.$store.commit("changeSessionId", data.object.SessionId);
      }
    },

    setUnblockMachines(data) {
      if (data.Ok) {
        this.$store.commit("changeBlockMachine", false);
      }
    },

    setBlockMachines(data) {
      if (data.Ok) {
        this.$store.commit("changeBlockMachine", true);
      }
    },

    setUnblockCommunication(data) {
      if (data.Ok) {
        this.$store.commit("changeCommunicatFion", true);
      }
    },

    setBlockCommunication(data) {
      if (data.Ok) {
        this.$store.commit("changeCommunication", false);
      }
    },

    setIsTouchScreen(data) {
      if (this.isElectronVar) {
        if (!this.isWindows && data.Ok) {
          hideCursor();
        } else {
          showCursor();
        }
      }
    },

    defaultNotification(data) {
      try {
        switch (data.MessageType) {
          case classValue.approved:
            notify.message("foo", "Success", data.Message[0], "success", 5000);
            break;
          case classValue.warning:
            notify.message("foo", "Warn", data.Message[0], "warn", 10000);
            break;
          case classValue.error:
            notify.message("foo", "Error", data.Message[0], "error", 10000);
            break;
          case classValue.info:
            notify.message("foo", "Info", data.Message[0], "info", 5000);
            break;
          default:
            console.log(
              "defaultNotification Unkown[ " + data.MessageType + " ]"
            );
            break;
        }
      } catch (e) {
        console.log("Error in defaultNotification() " + e);
      }
    },

    checkHasEmailSenderConfig() {
      if (this.idEmailSender > 0) {
        this.tabs.forEach((tab) => {
          if (tab.Name == "Configuration") {
            this.selected = tab.id;
          }
        })
      }
    },

    updateOperatorName(name) {
      this.cmd = clientCommands.SelectOperator.name;
      this.objData = {
        Value: name
      };
      this.countDown = clientCommands.SelectOperator.time;
      this.sendingCMDMessage =
        this.translate("waiting") + " " + this.translate("messageGetOperatorServer");
      this.route = "/modules/dashboard";
      this.opName = name;
      this.showSelectOperator = false
      this.showLoadingCMD = true;

    },

    actionAfterLoadingDashboard(data) {
      if (data.ok) {
        switch (this.cmd) {
          case clientCommands.SelectOperator.name:
            this.showSelectOperator = false;
            this.$store.commit("changeOperatorName", this.opName);
            break;
          default:
            break;
        }
      }
      else {
        switch (this.cmd) {
          case clientCommands.SelectOperator.name:
            this.showSelectOperator = true;
            break;
          default:
            break;
        }
      }
    },


    getDatas() {
      this.intervalGetDatas = setInterval(() => {
        try {
          if (Socket.returnStatus() == webSocketStatus.OPEN) {
            if (!this.stopRequest) {
              var cmdToSend = clientCommands.ServerData.name + "|" + this.currentTab;
              if (this.operatorName != undefined && this.operatorName != "null") {
                cmdToSend = cmdToSend + "#" + this.operatorName;
              }
              Socket.send(
                cmdToSend +
                "|" +
                this.token
              );
            }
          }
          if (!this.completeLogin) {
            console.log("Finish getData()");
            clearInterval(this.intervalGetDatas);
          }
        } catch (e) {
          notify.message("foo", "Error", e.message, "error", 2000);
        }
      }, 500);
    },
    checkModalSelectOperator(val) {
      if (val.length > 0 && this.opName == '' && !this.userLogged.ChangePassword) {
        this.showSelectOperator = false
        this.showSelectOperator = true
      }
    },
    showComponent(val) {
      this.selected = val
    }
  },
  mounted() {
    clearInterval(this.intervalGetDatas);
    if (this.tabs.length > 1) {
      this.icons = []
      this.list = []
      this.tabsName.forEach((tabName) => {
        this.tabs.forEach((tab) => {
          if (tab.Name == tabName) {
            tab.id = this.opensTabs;
            console.log("Mounted : Tab[" + tab.Name + "] Id[" + tab.id + "]");
            switch (tab.Name) {
              case "Operator":
                this.icons.push("icons-lg2 icons-white icons-group")
                this.list.push(this.translate('operator_Title'))
                this.opensTabs++;
                break;
              case "GlobalProgressive":
                this.icons.push("icons-lg2 icons-white icons-globe")
                this.list.push(this.translate('globalProgressive_Title'))
                this.opensTabs++;
                break;
              case "Client":
                this.icons.push("icons-lg2 icons-white icons-server-config")
                this.list.push(this.translate('client_Title'))
                this.opensTabs++;
                break;
              case "E-mail":
                this.icons.push("icons-lg2 icons-white icons-at")
                this.list.push(this.translate('email'))
                this.opensTabs++;
                break;
              case "Configuration":
                this.icons.push("icons-lg2 icons-white icons-cog")
                this.list.push(this.translate('configurations_Title'))
                this.opensTabs++;
                break;
            }
          }
        });
      });
    }
    this.$store.commit("setOpenTabs", this.opensTabs);
    this.$store.commit("setSelectedTab", this.tabs[0].Name);
    var r = document.querySelector(':root');
    r.style.setProperty('--cardBodyHeight', "105vh - 150px");    
    this.getDatas();
    this.checkHasEmailSenderConfig()
    this.opName = this.operatorName;
  },
  created() {
    //Socket.startWebSocket();
    Socket.$off("defaultNotificationCMD");
    this.$store.commit("changeStopRequest", false);
    Socket.$on("data", this.updateData);
    Socket.$on("noConnection", this.noConnection);
    Socket.$on("onConnection", this.onConnection);
    Socket.$on("loginRequiredCMD", this.loginRequired);
    Socket.$on("forcedDisconnectionCMD", this.forcedDisconnection);
    Socket.$on("hasEmailsToSendCMD", this.changeHasEmailsToSend);
    Socket.$on("updateDatabaseCMD", this.setUpdateConfigs);
    Socket.$on("openSessionAllCMD", this.setOpenSession);
    Socket.$on("closeSessionAllCMD", this.setCloseSession);
    Socket.$on("unblockMachinesAllCMD", this.setUnblockMachines);
    Socket.$on("blockMachinesALLCMD", this.setBlockMachines);
    Socket.$on("unblockCommunicationAllCMD", this.setUnblockCommunication);
    Socket.$on("blockCommunicationAllCMD", this.setBlockCommunication);
    Socket.$on("defaultNotificationCMD", this.defaultNotification);
    Socket.$on("toggleTouchscreen", this.setIsTouchScreen);


  },
  beforeDestroy() {
    console.log("/closed Dashboard");
    clearInterval(this.intervalGetDatas);
  },
  watch: {
    selected(value) {
      console.log(value);
      const opt1 = document.getElementById("quickMenu");
      opt1.classList.remove("active")
      try {
        const oldTab = this.currentTab;
        this.tabs.forEach((tab) => {
          if (tab.id == value) {
            console.log(tab.Name);
            this.$store.commit("setSelectedTab", tab.Name);
            return;
          }
        });
        if (oldTab == "ReportManager") {
          Socket.send(clientCommands.FinishGetReport.name + "|" + "" + "|" +
            this.token);
          this.$store.commit("setUserLoggedTemp", {});
        }
      } catch (e) {
        console.log("Error in ChangeTab() " + e);
      }
    },

    listOperatorFilter(val) {
      this.checkModalSelectOperator(val)
    }


  },
};
</script>
<style scoped></style>


