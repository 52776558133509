
import Vue from "vue"
import Socket from "@/config/webSocket.js";
import { clientCommands } from "@/js/ClientCommands.js";

const DriverSocket = new Vue({
    data() {
        return {
            cmdServerResponse: false,
            closeModal: false,
            sendCmd: null,
            cmd: "",
            object: {
                answered: false,
                cmd: '',
                data: {}
            }
        }
    },
    methods: {
        send(cmd, data, token, url) {            
            this.cmd = cmd
            let Try = 0;
            this.closeModal = false
            if (url != undefined && url != "") {
                Socket.sendBinary(cmd, data, token, url);
            } else {
                Socket.send(cmd + "|" + data + "|" + token);
            }

            Try++;
            console.log("Send CMD [" + cmd + "] " + Try + "°" + "try")
            this.sendCmd = setInterval(() => {
                if (Try == 3) {

                    console.log("No Response to [" + cmd + "] after " + Try + " Times")
                    clearInterval(this.sendCmd);
                    this.object.answered = false,
                        this.object.cmd = cmd
                    this.object.data = {}

                    DriverSocket.$emit("getCmdAnswer", this.object);
                    return;
                }
                if (!this.cmdServerResponse) {
                    if (url != "") {
                        Socket.sendBinary(cmd, data, token, url);
                    } else {
                        if (cmd == clientCommands.ServerData.name) {
                            Socket.send(cmd + "|" + data, + "|" + token);
                        } else {
                            setTimeout(() => Socket.send(cmd + "|" + data + "|" + token), 300);
                        }
                    }
                    Try++;
                    console.log("Send CMD [" + cmd + "] " + Try + "°" + "try")
                }
            }, 3000)
        },
        changeCmdServerResponse() {
            this.cmdServerResponse = true;
            this.finishGetAwnser()
        },

        finishGetAwnser() {
            clearInterval(this.sendCmd);
            this.object.answered = true,
                this.object.cmd = this.cmd
            this.object.data = {}
            DriverSocket.$emit("getCmdAnswer", this.object);
        },

        finishedCMD(data) {
            DriverSocket.$emit("finishedCMD", data);
        },
        finishedCMDUser(data) {
            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: 0,
                RestartRequired: false,

            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },
        finishedCMDDeleteUser(data) {
            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: 0,
                RestartRequired: false,

            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },
        finishedCMDReport(data) {
            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: 0,
                RestartRequired: false,

            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },
        finishedCMDDeleteReport(data) {
            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: 0,
                RestartRequired: false,

            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },
        finishedCMDGetToken(data) {
            DriverSocket.$emit("finishedCMDGetToken", data);
        },
        finishedCMDValidateToken(data) {
            DriverSocket.$emit("finishedCMDValidateToken", data);
        },
        finishedCMDGetHashCode(data) {
            DriverSocket.$emit("finishedCMDGetHashCode", data);
        },
        finishedCMDCommunication(data) {
            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: {},
                TimeToRestart: 0,
                RestartRequired: false,
            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },
        finishedCMDLogin(data) {
            DriverSocket.$emit("finishedCMDLogin", data);
        },
        finishedCMDCreateIni(data) {

            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: {},
                TimeToRestart: data.TimeToRestart,
                RestartRequired: data.RestartRequired,
            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },
        finishedCMDReportManagerData(data) {
            DriverSocket.$emit("finishedCMDReportManagerData", data);
        },

        finishedCMDResetModule(data) {

            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: {},
                TimeToRestart: data.TimeToRestart,
                RestartRequired: data.RestartRequired,
            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },

        finishedCMDCashierOption(data, binaryData = null) {

            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: data.TimeToRestart,
                RestartRequired: data.RestartRequired,
                MessageType: data.MessageType,
                BinaryData: binaryData,
            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },

        finishedCMDLogged(data) {

            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: data.TimeToRestart,
                RestartRequired: data.RestartRequired,
            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },

        finishedCMDAccount(data) {
            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: 0,
                RestartRequired: false,

            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },

        finishedDefault(data, binaryData = null) {
            this.cmdServerResponse = true;
            const obj = {
                Ok: data.Ok,
                Message: data.Message,
                Object: data.Object,
                TimeToRestart: data.TimeToRestart,
                RestartRequired: data.RestartRequired,
                MessageType: data.MessageType,
                BinaryData: binaryData,
            }
            this.finishGetAwnser()
            this.finishedCMD(obj)
        },
    },
    created() {
        Socket.$on("confirmation", this.changeCmdServerResponse);
        Socket.$on("openSessionCMD", this.finishedCMD);
        Socket.$on("unblockMachineCMD", this.finishedCMD);
        Socket.$on("closeSessionCMD", this.finishedCMD);
        Socket.$on("blockMachinesCMD", this.finishedCMD);
        Socket.$on("createUserCMD", this.finishedCMDUser);
        Socket.$on("editUserCMD", this.finishedCMDUser);
        Socket.$on("deleteUserCMD", this.finishedCMDDeleteUser);
        Socket.$on("createReportCMD", this.finishedCMDReport);
        Socket.$on("editReportCMD", this.finishedCMDReport);
        Socket.$on("deleteReportCMD", this.finishedCMDDeleteReport);
        Socket.$on("getTokenCMD", this.finishedCMDGetToken);
        Socket.$on("validateTokenCMD", this.finishedCMDValidateToken);
        Socket.$on("blockCommunicationCMD", this.finishedCMDCommunication);
        Socket.$on("unblockCommunicationCMD", this.finishedCMDCommunication);
        Socket.$on("serverRestartCMD", this.finishedCMD);
        Socket.$on("serverCloseCMD", this.finishedCMD);
        Socket.$on("loginCMD", this.finishedCMDLogin);
        Socket.$on("downloadConfigCMD", this.finishedCMD);
        Socket.$on("uploadConfigCMD", this.finishedCMD);
        Socket.$on("installConfigCMD", this.finishedCMD);
        Socket.$on("requestConfigCMD", this.finishedDefault);
        Socket.$on("createIniCMD", this.finishedCMDCreateIni);
        Socket.$on("reportManagerDataCMD", this.finishedCMDReportManagerData);
        Socket.$on("getReportCMD", this.finishedCMD);
        Socket.$on("progMultiResetCMD", this.finishedCMDResetModule);
        Socket.$on("bonusResetCMD", this.finishedCMDResetModule);
        Socket.$on("cashierSignIn", this.finishedCMDCashierOption);
        Socket.$on("cashierSignOut", this.finishedCMDCashierOption);
        Socket.$on("loggedInCMD", this.finishedCMDLogged);
        Socket.$on("loggedOutCMD", this.finishedCMDLogged);
        Socket.$on("cashierBlock", this.finishedCMDCashierOption);
        Socket.$on("cashierUnblock", this.finishedCMDCashierOption);
        Socket.$on("cashierAccountInfo", this.finishedCMDCashierOption);
        Socket.$on("cashierCloseAccountRequest", this.finishedCMDCashierOption);
        Socket.$on("cashierCloseAccountOk", this.finishedCMDCashierOption);
        Socket.$on("cashierCloseAccountCancel", this.finishedCMDCashierOption);
        Socket.$on("cashierRegisterFill", this.finishedCMDCashierOption);
        Socket.$on("cashierRegisterDrop", this.finishedCMDCashierOption);
        Socket.$on("cashierAddNewPin", this.finishedCMDCashierOption);
        Socket.$on("cashierAddNewPin", this.finishedCMDCashierOption);
        Socket.$on("cashierAccounts", this.finishedCMDCashierOption);
        Socket.$on("cashierReprintTicket", this.finishedCMDCashierOption);
        Socket.$on("cashierReport", this.finishedCMDCashierOption);
        Socket.$on("serverLogs", this.finishedCMDCashierOption);
        Socket.$on("cashierCopyFiles", this.finishedDefault);
        Socket.$on("certificateReport", this.finishedDefault);
        Socket.$on("copyCertificate", this.finishedDefault);
        Socket.$on("serverLogsCopyFiles", this.finishedCMDCashierOption);
        Socket.$on("serverReportsCopyFilesCMD", this.finishedCMDCashierOption);
        Socket.$on("cashierAddCredits", this.finishedCMDCashierOption);
        Socket.$on("generateServerReports", this.finishedCMDAccount);
        Socket.$on("generateCSV", this.finishedCMDAccount);
        Socket.$on("setTerminalProgressive", this.finishedDefault);
        Socket.$on("setLanguage", this.finishedDefault);
        Socket.$on("exportReport", this.finishedDefault);
        Socket.$on("sendManualEmail", this.finishedDefault);
        Socket.$on("sendTestEmail", this.finishedDefault);
        Socket.$on("setMaxFileSizeCMD", this.finishedDefault);
        Socket.$on("showMenu", this.finishedDefault);
        Socket.$on("hideMenu", this.finishedDefault);
        Socket.$on("paymentTerminal", this.finishedDefault);
        Socket.$on("openTerminal", this.finishedDefault);
        Socket.$on("deleteTerminal", this.finishedDefault);
        Socket.$on("resendToQueue", this.finishedDefault);
        Socket.$on("getPenDriveListCMD", this.finishedDefault);
        Socket.$on("doBackupCMD", this.finishedDefault);
        Socket.$on("doRestoreCMD", this.finishedDefault);
        Socket.$on("deleteDatabaseCMD", this.finishedDefault)
        Socket.$on("requestBackup", this.finishedDefault);
        Socket.$on("forcePigBoom", this.finishedDefault);
        Socket.$on("forceMystery", this.finishedDefault);
        Socket.$on("forceMultiProgressiveLevel", this.finishedDefault);
        Socket.$on("clearForceLevelList", this.finishedDefault);
        Socket.$on("getHashCode", this.finishedDefault);
        Socket.$on("resetHashCode", this.finishedDefault);
        Socket.$on("validateHashCode", this.finishedDefault);
        Socket.$on("addCash", this.finishedDefault);
        Socket.$on("requestHashCodeFile", this.finishedDefault);
        Socket.$on("getPromotionsInfoAndPremiums", this.finishedDefault);
        Socket.$on("validateCoupon", this.finishedDefault);
        Socket.$on("validateS2S", this.finishedDefault);
        Socket.$on("updateCoupon", this.finishedDefault);
        Socket.$on("resetServerConfiguration", this.finishedDefault);
        Socket.$on("resetIpConfiguration", this.finishedDefault);
        Socket.$on("resetBothConfigurations", this.finishedDefault);
        Socket.$on("validateErrorCode", this.finishedDefault);
        Socket.$on("generateCoupon", this.finishedDefault);
        Socket.$on("findByVlt", this.finishedDefault);
        Socket.$on("refreshMachinesPrintCoupon", this.finishedDefault);
        Socket.$on("validateDigi", this.finishedDefault);
        Socket.$on("machineOperation", this.finishedDefault);
        Socket.$on("saveBigOsSettings", this.finishedDefault);
        Socket.$on("showLogs", this.finishedDefault);
        Socket.$on("sendFileCMD", this.finishedDefault);
        Socket.$on("cashierCheckCMD", this.finishedDefault)
        Socket.$on("printReportCMD", this.finishedDefault)
        Socket.$on("gistraReportCMD", this.finishedDefault)
        Socket.$on("voucherViewerCMD", this.finishedDefault)
        Socket.$on("navisionReportCMD", this.finishedDefault)
        Socket.$on("globalSettingsCMD", this.finishedDefault)
        Socket.$on("runOsAdminCMD", this.finishedDefault)
        Socket.$on("saveFooterCMD", this.finishedDefault)
        Socket.$on("editFooterCMD", this.finishedDefault)
        Socket.$on("deleteFooterCMD", this.finishedDefault)
        Socket.$on("deletePlaylistCMD", this.finishedDefault)
        Socket.$on("displayPanelNameCMD", this.finishedDefault)
        Socket.$on("createPlaylistCMD", this.finishedDefault)
        Socket.$on("editPlaylistCMD", this.finishedDefault)
        Socket.$on("setPanelDataCMD", this.finishedDefault)
        Socket.$on("deletePanelCMD", this.finishedDefault)
        Socket.$on("resetFactoryPanelCMD", this.finishedDefault)
        Socket.$on("restartPanelCMD", this.finishedDefault)
        Socket.$on("shutdownPanelCMD", this.finishedDefault)
        Socket.$on("navisionReportCopyFilesCMD", this.finishedDefault)
        Socket.$on("disableEmailSender", this.finishedDefault)
        Socket.$on("serverReportsCMD", this.finishedDefault)
        Socket.$on("generateLicense", this.finishedDefault)
        Socket.$on("automaticLicenseReq", this.finishedDefault)
        Socket.$on("validateLicense", this.finishedDefault)
        Socket.$on("cancelLicenseReq", this.finishedDefault)
        Socket.$on("requestLicense", this.finishedDefault)
        Socket.$on("checkLicenseReq", this.finishedDefault)
        Socket.$on("couponViewCMD", this.finishedDefault)
        Socket.$on("printTestCouponCMD", this.finishedDefault)
        Socket.$on("printCouponCMD", this.finishedDefault)
        Socket.$on("getOperatorServerCMD", this.finishedDefault)
        Socket.$on("clientOptionsCMD", this.finishedDefault)
        Socket.$on("deleteOperatorCMD", this.finishedDefault)
        Socket.$on("selectOperatorCMD", this.finishedDefault)

    }
});

export default DriverSocket