<template >
  <div id="app">

    <Header v-if="!validatingLogin" />
    <Loading v-if="validatingLogin" />
    <Content v-else />
    <Footer v-if="!validatingLogin" :key="validateLogin" />

    <input tabindex="-1" v-model="hideShortcut" style="
                                height: 0px;
                                width: 0px;
                                margin-left: 0px;
                                margin-top: 0px;
                                left: -10%;
                                position: absolute;
                              " id="lblHideshortcut" name="lblHideshortcut" />
    <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <template #default="props">
        <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown">
        </LoadingCMD>
      </template>
    </b-modal>
    <b-modal v-model="showModalNoConnection" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog"
      aria-modal :can-cancel="false">
      <template #default="props">
        <NoConnection @close="props.close" />
      </template>
    </b-modal>
    <b-modal v-model="showModalReturnApp" width="915px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <template #default="props">
        <ReturnApp :automatic="false" @close="props.close" :obj="data" />
      </template>
    </b-modal>
  </div>
</template>

<script>



import Loading from "@/components/template/Loading";
import Header from "@/components/template/Header";
import Content from "@/components/template/Content";
import Footer from "@/components/template/Footer";
import NoConnection from "@/components/connection/noConnection.vue";
import Socket from "@/config/webSocket.js";
import SocketDriver from "@/config/webSocketDriver.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import ReturnApp from "@/components/reset/ReturnApp.vue";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import { countries } from "@/mixins/countryCode.js";
import { passwordFront, colorFBM, colorDINGO, colorGlobal } from "@/global.js";
import { os, getFile } from "@/js/osType.js";
import {
  topMost,
  showMenuBar,
  checkTouchScreen,
  isElectron,
  hideCursor,
} from "@/js/screenAdjustment";
import "@/css/notify.css";
import "@/css/icons-1.0.0/style.css";
import "@/css/style.css";
import "@/css/variables.css";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";

export default {

  name: "App",
  mixins: [en, es, nor, swe],
  computed: mapState([
    "user",
    "userLogged",
    "hasDatabase",
    "IP",
    "completeLogin",
    "lang",
    "showHideBtns",
    "locale",
    "errorS2S",
    "errorGlobalClient",
    "validatingLogin",
    "processingLoading",
    "showModalNoConnection",
    "isElectronVar",
    "isWindows",
    "isTouchScreen",
    "env",
    "showOsSettings",
    "firstPowerUp",
    "isFBM",
    "actualTimeZone",

  ]),

  props: {
    title: String,
  },
  components: {
    Header,
    Content,
    Footer,
    Loading,
    NoConnection,
    LoadingCMD,
    ReturnApp,
  },
  data: function () {
    return {
      iniFile: null,
      hideShortcut: "",
      showLoadingCMD: false,
      cmd: "",
      sendingCMDMessage: "",
      countDown: 0,
      momentInstance: this.moment(),
      firstTime: true,
      intervalNoConnection: null,
      showModalReturnApp: false,
      data: {},

    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    initProg(data) {
      if (data.Ok) {
        this.$store.commit("changeValidatingLogin", true);
        clearInterval(this.intervalNoConnection);
        console.log("initProg APP");
        this.user.isElectron = this.isElectronVar;
        this.user.Token = localStorage.getItem("Token" + this.IP)
        Socket.send(clientCommands.Login.name + "|" + JSON.stringify(this.user) + "|" + "");
      } else {
        //
        console.log("Front with not Permission.")
        data.TimeToRestart = 10
        this.data = data
        this.showModalReturnApp = true
      }
    },

    setLang(countryCode) {
      switch (countryCode) {
        case countries.Spain:
        case countries.Mexico:
          this.$store.commit("setLang", "es");
          this.$store.commit("setLocale", "es-ES");
          break;
        case countries.Norway:
          this.$store.commit("setLang", "nor");
          this.$store.commit("setLocale", "nn-NO");
          break;
        case countries.Sweden:
          this.$store.commit("setLang", "swe");
          this.$store.commit("setLocale", "sv-SE");
          break;
        case countries.Philippines:
        case countries.DingoPHL:
        case countries.Ireland:
        default:
          this.$store.commit("setLang", "en");
          this.$store.commit("setLocale", "en-US");
          break;
      }
    },

    setLocale(countryCode) {
      switch (countryCode) {
        case countries.Spain:
        case countries.Mexico:
          this.$store.commit("setLang", "es");
          this.$store.commit("setLocale", "es-ES");
          break;
        case countries.Norway:
          this.$store.commit("setLang", "nor");
          this.$store.commit("setLocale", "nn-NO");
          break;
        case countries.Sweden:
          this.$store.commit("setLang", "swe");
          this.$store.commit("setLocale", "sv-SE");
          break;
        case countries.Philippines:
        case countries.DingoPHL:
        case countries.Ireland:
        default:
          this.$store.commit("setLang", "en");
          this.$store.commit("setLocale", "en-US");
          break;
      }
    },

    validateLogin(data) {
      try {
        this.$store.commit("changeShowModalNoConnection", false);
        this.$store.commit("changeFirstPowerUp", false);
        this.$store.commit("setInitialConfigs", data.Object);
        this.$store.commit("setCountryCode", this.user.countryCode);
        this.$store.commit("changeHasIniFile", true);

        let tokenTemp = localStorage.getItem("Token" + this.IP)
        localStorage.clear();
        localStorage.setItem("ipTemp", this.IP)
        localStorage.setItem("iconTemp" + this.IP, 'favicon.ico');
        localStorage.setItem("nameTemp" + this.IP, 'Megapot Server');
        localStorage.setItem("Token" + this.IP , tokenTemp);
        if (data.Object.Lang == "") {
          this.$store.commit("setLang", this.user.countryCode);
        } else {
          this.$store.commit("setLang", data.Object.Lang);
          switch (this.lang) {
            case "es":
              this.$store.commit("setLocale", "es-ES");
              break;
            case "nor":
              this.$store.commit("setLocale", "nn-NO");
              break;
            case "swe":
              this.$store.commit("setLocale", "sv-SE");
              break;
            case "en":
            default:
              this.$store.commit("setLocale", "en-US");
              break;
          }
        }
        var r = document.querySelector(':root');
        if (this.isFBM) {
          r.style.setProperty('--iconColor', colorFBM);
        } else {
          r.style.setProperty('--iconColor', colorDINGO);
        }

        r.style.setProperty('--iconColor', colorGlobal);
        if (!data.Object.ExistsIniFile && this.showOsSettings == false) {
          this.$store.commit("changeValidatingLogin", false);
          this.$store.commit("changeHasIniFile", false);
          if (this.$route.path != "/ini") {
            {
              this.$router.push("/ini").catch((e) => {
                console.log("Error in Route->Ini()" + e);
              });
            }
          }
          return;
        } else {
          this.$store.commit("changeValidatingLogin", false);
          if (
            this.userLogged != null ||
            this.showOsSettings
          ) {
            this.$store.commit("changeCompleteLogin", true);
            if (this.$route.path != "/home") {
              this.$router.push("/home").catch((e) => {
                console.log("Error in Route->Home()" + e);
              });
            }
          } else {
            if (this.$route.path != "/login") {
              {
                this.$router.push("/login").catch((e) => {
                  console.log("Error in Route->Login()" + e);
                });
              }
            }
          }

          return;
        }

      } catch (e) {
        console.log("Error in validateLogin()" + e);
      }
    },

    noConnection() {
      try {
        if (this.firstPowerUp) {
          if (this.firstTime) {
            this.firstTime = false;
            let time = 0;
            if (this.intervalNoConnection == null) {
              this.intervalNoConnection = setInterval(() => {
                console.log(time);
                time++;
                if (
                  this.validatingLogin == undefined ||
                  !this.validatingLogin
                ) {
                  clearInterval(this.intervalNoConnection);
                }
                if (time == 60) {
                  this.$store.commit("changeShowModalNoConnection", true);
                  this.$store.commit("changeFirstPowerUp", false);
                  clearInterval(this.intervalNoConnection);
                }
              }, 1000);
            }
          }
        } else {
          this.$store.commit("changeShowModalNoConnection", true);
        }
        const intervalRetryConnection = setInterval(() => {
          if (!this.showModalNoConnection) {
            clearInterval(intervalRetryConnection);
          }
        }, 5000);
      } catch (e) {
        console.log("Error in noConnection() " + e);
        clearInterval(this.intervalNoConnection);
      }
    },

    checkIniFile() {
      try {
        this.iniFile = getFile();
        if (this.iniFile != null) {
          console.log("file found");
          this.$store.commit("changeIP", this.iniFile.IP);
        }
      } catch (e) {
        console.log("sorry, file not found" + e);
      }
    },

    processShowOrHideMenu() {
      console.log("You pressed ctrl & 2");
      setTimeout(function () {
        document.getElementById("lblHideshortcut").blur();
        console.log("lblHideshortcut Lost Focus");
      }, 10000);
      this.hideShortcut = "";
      document.getElementById("lblHideshortcut").focus();
    },
    showOrHideMenu() {
      try {
        this.cmd = "";
        this.sendingCMDMessage = "";
        this.countDown = "";
        if (!this.showHideBtns) {
          this.cmd = clientCommands.ShowMenu.name;
          this.sendingCMDMessage = this.translate("hideShowMessage");
          this.showLoadingCMD = true;
          this.countDown = clientCommands.ShowMenu.time;
        }
      } catch (e) {
        console.log("Error in showOrHideMenu() " + e);
      }
    },
    logIt(e) {
      if (!this.showHideBtns) {
        console.log(e.key + " is pressed: Shortcut Disabled");
        return false;
      } else {
        console.log(e.key + " is pressed: Shortcut Enabled");
      }
    },
  },

  created() {
    //Reset Store
    this.$store.commit("resetState", "en");
    //Default Lang = en
    this.$store.commit("setLang", "en");
    
    let endpointAddress = "";    
    if (window.location.hostname == "." || window.location.hostname == "") {
      endpointAddress = "127.0.0.1";
    } else {
      endpointAddress = window.location.hostname;
    }
    //endpointAddress = ""; // Use this as a custom address for test purposes
    Socket.startWebSocket(endpointAddress);
    this.$store.commit("changeIP", endpointAddress);

    Socket.$on("noConnection", this.noConnection);
    Socket.$on("connectionPermissionCMD", this.initProg);
    SocketDriver.$on("finishedCMDLogin", this.validateLogin);

    if (this.isElectronVar) {
      hideCursor()
    }
  },
  mounted() {
    if (this.showOrHideMenu) {
      Socket.send(clientCommands.HideMenu.name + "|");
    }

    this.setTime = setInterval(() => {
      this.momentInstance = this.moment();
      this.momentInstance.utcOffset(this.actualTimeZone)
      this.momentInstance.locale(this.locale);
      this.$store.commit("setSystemTime", this.momentInstance);
    }, 1000);
    this.$store.commit("changeIsElectron", isElectron());
    this.$store.commit("setEnv", process.env.NODE_ENV);
    if (this.isElectronVar) {
      console.log("OS: " + os.platform());
      this.$store.commit("changeIsTouchScreen", checkTouchScreen());
      topMost(false);
      showMenuBar(false);
    }
    //shortcuts blocked
    if (this.env != "development") {
      this.$mousetrap.bind("ctrl+shift+r", this.logIt);
      this.$mousetrap.bind("ctrl+r", this.logIt);
      this.$mousetrap.bind("ctrl+2", this.processShowOrHideMenu);
      this.$mousetrap.bind("ctrl+shift+i", this.logIt);
      this.$mousetrap.bind("ctrl+w", this.logIt);
      this.$mousetrap.bind("ctrl+m", this.logIt);
      this.$mousetrap.bind("f11", this.logIt);
      this.$mousetrap.bind("f5", this.logIt);
      this.$mousetrap.bind("alt+f4", this.logIt);
      this.$mousetrap.bind("alt+tab", this.logIt);
    }
  },
  watch: {
    hideShortcut(value) {
      if (
        passwordFront.length == 4 &&
        value == passwordFront &&
        this.completeLogin &&
        !this.processingLoading &&
        this.isElectronVar &&
        this.isWindows
      ) {
        this.showOrHideMenu();
        this.hideShortcut = "";
        document.getElementById("lblHideshortcut").blur();
      } else if (value.length > 4) {
        this.hideShortcut = "";
        document.getElementById("lblHideshortcut").blur();
      }
    },

    validatingLogin(val) {
      if (!val) {
        clearInterval(this.intervalNoConnection);
      }
    },
  },


};
</script>

<style >
* {
  font-family: "Robot", sans-serif;
  font-size: 15px;
}

body {
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  display: grid;
  grid-template-rows: 40px 1fr 40px;

  grid-template-areas:
    "header"
    "content"
    "rodape";
}

#app.hide-menu {
  grid-template-areas:
    "header"
    "content"
    "rodape";
}
</style>
