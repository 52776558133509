

<template>
  <form action="" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto">
      <div class="loadingCMD">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
          <div class="circle"></div>
          <div class="circle-small"></div>
          <div class="circle-big"></div>
          <div class="circle-inner-inner"></div>
          <div class="circle-inner"></div>
          <div class="textHeader" style="top: 65%">
            <span style="color: white">
              {{ sendingCMDMessage }}
            </span>
          </div>
        </b-loading>
      </div>
    </div>
  </form>
</template>

<script>
import SocketDriver from "@/config/webSocketDriver.js";
import notify from "@/config/notify.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
import { clientCommands } from "@/js/ClientCommands";
import { closeWindow, topMost } from "@/js/screenAdjustment.js";
import { downloadFile, uploadFile } from "@/js/downloadAndUploadFile.js";
import { mapState } from "vuex";
export default {
  name: "LoadingCMD",
  computed: mapState(["lang", "env", "isElectronVar", "token"]),
  mixins: [en, es, nor, swe],
  components: {},
  data: function () {
    return {
      isLoading: true,
      stopCountdown: false,
      firstCMDanwser: true,
      countDownData: this.countDown + 30,
      intervalWaitingAxios: null,
    };
  },
  props: {
    sendingCMDMessage: String,
    CMD: String,
    countDown: Number,
    Action: Function,
    route: String,
    JsonObject: Object,
    isAxios: Boolean,
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    close() {
      this.$store.commit("changeProcessingLoading", false);
      this.$emit("close");
    },
    sendCMD() {
      try {
        this.$store.commit("changeStopRequest", true);
        this.$store.commit("changeProcessingLoading", true);
        this.firstCMDanwser = true;
        this.stopCountdown = false;
        let data = "";
        let sendBinary = false;
        if (this.JsonObject != undefined) {
          switch (this.CMD) {
            case clientCommands.UploadConfig.name:
            case clientCommands.DoRestore.name:
            case clientCommands.ValidateLicense.name:
            case clientCommands.SendFile.name:              
              if (!this.isElectronVar) {
                data = this.JsonObject;
                sendBinary = true;
              } else {
                data = this.JsonObject.data;
              }
              break;
            case clientCommands.DeleteReport.name:
              data = this.JsonObject.Name;
              break;
            case clientCommands.CashierAccounts.name:
            case clientCommands.CashierAddNewPin.name:
            case clientCommands.GenerateServerReports.name:
            case clientCommands.GenerateCsv.name:
            case clientCommands.SetLanguage.name:
            case clientCommands.ValidateHashCode.name:
            case clientCommands.ValidateCoupon.name:
            case clientCommands.ValidateErrorCode.name:
            case clientCommands.FindByVlt.name:
            case clientCommands.ValidateDigi.name:
            case clientCommands.ClearForceLevelList.name:
            case clientCommands.MachineOperation.name:
            case clientCommands.RunOsAdmin.name:
            case clientCommands.SaveFooter.name:
            case clientCommands.DeleteFooter.name:
            case clientCommands.DeletePlaylist.name:
            case clientCommands.ForceMultiProgressiveLevel.name:
            case clientCommands.ForceMystery.name:
            case clientCommands.ForcePigBoom.name:
            case clientCommands.DisableEmailSender.name:
            case clientCommands.CouponView.name:
            case clientCommands.GetOperatorServer.name:
            case clientCommands.SelectOperator.name:
              data = this.JsonObject.Value;
              if (data == undefined) {
                data = "";
              }
              break;
            case clientCommands.CashierAccountInfo.name:
            case clientCommands.CashierCloseAccountRequest.name:
            case clientCommands.CashierCloseAccountCancel.name:
              data = this.JsonObject.pin;
              break;
            default:
              data = JSON.stringify(this.JsonObject);
              break;
          }
        }
        if (sendBinary) {
          SocketDriver.send(this.CMD, data.File, this.token, data.url);
        }
        else {
          SocketDriver.send(this.CMD, data, this.token);
        }
      } catch (e) {
        console.log("Error in sendCMD() " + e);
      }
    },
    cmdAnswer(object) {
      if (this.firstCMDanwser) {
        if (!object.answered) {
          notify.message(
            "foo",
            this.translate("lblError"),
            this.translate("messageServerDidNotRespond") +
            " [" +
            object.cmd +
            "] " +
            this.translate("messageServerAttempts"),
            "error",
            5000
          );
          this.checkCMDError(object);
          this.close();
        } else {
          if (this.env == "development") {
            notify.message(
              "foo",
              this.translate("lblSucess"),
              this.translate("messageServerRecive") + " [" + object.cmd + "]",
              "success",
              500
            );
          }
          this.waitingForFinalCmd(object);
        }
        this.firstCMDanwser = false;
      }
    },

    finishedCMD(data) {
      this.stopCountdown = true;
      try {
        this.objectCMD = {
          ok: data.Ok,
          message: data.Message,
          object: data.Object,
          cmd: this.CMD,
          timeToRestart: data.TimeToRestart,
          restartRequired: data.RestartRequired,
          messageType: data.MessageType,
          binaryData: data.BinaryData,
        };
        this.checkDownload(data.BinaryData, data.Message);
        data.BinaryData = null;
      } catch (e) {
        console.log("Error in finishedCMD() " + e);
      }
    },

    checkDownload(binaryData, name) {
      if (binaryData != null && binaryData != undefined && binaryData.byteLength > 0) {
        let fileURL = window.URL.createObjectURL(new Blob([binaryData]));
        let fileLink = document.getElementById(name);
        if (fileLink == null || fileLink == undefined) {
          fileLink = document.createElement('a');
          fileLink.setAttribute("id", name);
        }
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.respFromAxiosOK()
      }
    },

    respFromAxiosOK() {
      this.objectCMD = {
        Ok: true,
        Message: "",
        Object: {},
        TimeToRestart: 1,
        RestartRequired: false,
        MessageType: "",
      };
      this.respFromAxios(this.objectCMD);
    },

    respFromAxiosFalse() {
      notify.message(
        "foo",
        this.translate("lblError"),
        this.translate("messageServerDidNotRespond") +
        "[" +
        this.CMD +
        "] " +
        this.translate("messageLimitTime"),
        "error",
        5000
      );
      this.objectCMD = {
        Ok: false,
        Message: "",
        Object: {},
        TimeToRestart: 1,
        RestartRequired: false,
        MessageType: "",
      };
      this.respFromAxios(this.objectCMD);
    },

    respFromAxios(data) {
      try {
        this.objectCMD = {
          ok: data.Ok,
          message: data.Message,
          object: data.Object,
          cmd: this.CMD,
          timeToRestart: data.TimeToRestart,
          restartRequired: data.RestartRequired,
          messageType: data.MessageType,
        };

        this.stopCountdown = true;
        this.countDownData = 2;
        this.processFinalCMD(this.objectCMD);
      } catch (e) {
        console.log("Error in respFromAxios() " + e);
      }
    },

    checkCMDError(object) {
      this.$store.commit("changeStopRequest", false);
      try {
        switch (object.cmd) {
          case clientCommands.UploadConfig.name:
          case clientCommands.DownloadConfig.name:
          case clientCommands.DoBackup.name:
          case clientCommands.DoRestore.name:
          case clientCommands.DeleteDatabase.name:
          case clientCommands.ValidateLicense.name:
          case clientCommands.CancelLicenseReq.name:
          case clientCommands.SaveBigOsSettings.name:
          case clientCommands.SendFile.name:
          case clientCommands.InstallConfig.name:
          case clientCommands.RunOsAdmin.name:
          case clientCommands.DisableEmailSender.name:
          case clientCommands.GenerateLicense.name:
          case clientCommands.AutomaticLicenseReq.name:
            object.ok = false;
            this.$emit("ActionAfterCMDConfig", object);
            break;
          case clientCommands.BlockMachines.name:
          case clientCommands.UnblockMachines.name:
          case clientCommands.OpenSession.name:
          case clientCommands.CloseSession.name:
            object.ok = false;
            this.$emit("ActionAfterCMDManagerOptions", this.objectCMD);
            break;
          case clientCommands.PrintReport.name:
            this.$store.commit("changeShowAlertPrintReport", false);
            this.$emit(
              "ActionAfterCMDManagerOptions",
              this.objectCMD
            );
            break;
          case clientCommands.LoggedIn.name:
            this.$emit("ActionAfterLogin", this.objectCMD);
            break;
          case clientCommands.CashierAddCredits.name:
          case clientCommands.CashierCloseAccountCancel.name:
            this.$emit("ActionAfterTicket", this.objectCMD);
            break;
          case clientCommands.ForcePigBoom.name:
          case clientCommands.ForceMystery.name:
          case clientCommands.ForceMultiProgressiveLevel.name:
          case clientCommands.ClearForceLevelList.name:
            this.$emit("ActionAfterDebug", this.objectCMD);
            break;
          case clientCommands.ValidateHashCode.name:
            this.$emit("ActionAfterAddCash", this.objectCMD);
            break;
          case clientCommands.ValidateCoupon.name:
          case clientCommands.UpdateCoupon.name:
            this.$emit("ActionAfterValidateCoupon", this.objectCMD);
            break;
          case clientCommands.ValidateErrorCode.name:
          case clientCommands.GenerateCoupon.name:
            this.$emit("ActionAfterGenerateCoupon", this.objectCMD);
            break;
          case clientCommands.ValidateS2S.name:
            this.$emit("ActionAfterCMDCreateIni", this.objectCMD);
            break;
          case clientCommands.SaveFooter.name:
          case clientCommands.EditFooter.name:
          case clientCommands.CreatePlaylist.name:
          case clientCommands.EditPlaylist.name:
            this.$emit("ActionAfterLoadingPlaylistSettings", this.objectCMD);
            break;
          case clientCommands.DeleteFooter.name:
          case clientCommands.DeletePlaylist.name:
          case clientCommands.SetPanelData.name:
            this.$emit("ActionAfterLoadingPanel", this.objectCMD);
            break;
          case clientCommands.VoucherViewer.name:
            this.$emit("ActionAfterCMDManagerOptions", this.objectCMD);
            break;
          case clientCommands.CouponView.name:
          case clientCommands.PrintTestCoupon.name:
          case clientCommands.PrintCoupon.name:
            this.$emit("ActionAfterCouponManagement", this.objectCMD);
            break;
          case clientCommands.SelectOperator.name:
            switch (this.route) {
              case "/modules/dashboard":
                this.$emit("ActionAfterLoadingDashboard", this.objectCMD);
                break;
              case "/modules/header":
                this.$emit("ActionAfterSelectOperator", this.objectCMD);
                break;
              default:
                break;
            }
            break;
          case clientCommands.ClientOptions.name:
            this.$emit("ActionAfterClientOptions", this.objectCMD);
            break;
          default:
            console.log(
              "Error in checkCMDError() Unknown CMD [ " + object.cmd + " ]"
            );
            break;
        }
      } catch (e) {
        console.log("Error in checkCMDError() " + e);
      }
    },

    waitingForFinalCmd: function (object) {
      if (this.countDownData > 0) {
        setTimeout(() => {
          this.processFinalCMD(object);
        }, 1000);
      }
    },

    waitingForRespAxios() {
      let time = 0;
      try {
        this.intervalWaitingAxios = setInterval(() => {
          time = time + 1;
          if (time >= this.countDownData) {
            console.log("/closed waitingForRespAxios()");
            clearInterval(this.intervalWaitingAxios);
            this.respFromAxiosFalse();
          }
        }, 1000);
      } catch (e) {
        console.log("error in waitingForRespAxios() " + e);
      }
    },

    checkCmdNotLimit() {
      let ret = true;
      switch (this.CMD) {
        case clientCommands.RunOsAdmin.name:
        case clientCommands.MachineOperation.name:
        case clientCommands.DoBackup.name:
        case clientCommands.DoRestore.name:
        case clientCommands.DeleteDatabase.name:
        case clientCommands.NavisionReportCopyFiles.name:
        case clientCommands.ServerLogsCopyFiles.name:
        case clientCommands.ServerReportsCopyFiles.name:
        case clientCommands.CopyCertificate.name:
        case clientCommands.CashierCopyFiles.name:
        case clientCommands.GenerateLicense.name:
        case clientCommands.AutomaticLicenseReq.name:
          ret = false;
          break;
      }

      return ret;
    },

    processFinalCMD(object) {
      this.countDownData -= 1;
      try {
        if (this.countDownData == 0 && this.checkCmdNotLimit()) {
          this.close();
          notify.message(
            "foo",
            this.translate("lblError"),
            this.translate("messageServerDidNotRespond") +
            "[" +
            object.cmd +
            "] " +
            this.translate("messageLimitTime"),
            "error",
            5000
          );
          this.checkCMDError(object);
        } else {
          if (this.stopCountdown) {
            if (!this.objectCMD.ok) {
              this.close();
              let messageResp = "";
              if (this.objectCMD.cmd == clientCommands.BlockMachines.name || this.objectCMD.cmd == clientCommands.UnblockMachines.name) {
                messageResp = this.objectCMD.message[0].split("#");
                messageResp = messageResp[1];
              } else {
                messageResp = this.objectCMD.message[0]
              }
              notify.message(
                "foo",
                this.translate("lblError"),
                messageResp,
                "error",
                5000
              );
              this.checkCMDError(this.objectCMD);
            } else {
              this.objectCMD.cmd = object.cmd;

              this.$store.commit("changeStopRequest", false);
              switch (object.cmd) {
                case clientCommands.OpenSession.name:
                case clientCommands.CloseSession.name:
                case clientCommands.GistraReport.name:
                case clientCommands.NavisionReport.name:
                case clientCommands.VoucherViewer.name:
                  this.$emit("ActionAfterCMDManagerOptions", this.objectCMD);
                  break;
                case clientCommands.UnblockMachines.name:
                  this.$store.commit("changeBlockMachine", false);
                  this.$emit("ActionAfterCMDManagerOptions", this.objectCMD);
                  break;
                case clientCommands.BlockMachines.name:
                case clientCommands.AutoBlockMachines.name:
                  this.$store.commit("changeBlockMachine", true);
                  switch (this.route) {
                    case "/header/restart":
                      this.$emit("ActionFromLoading");
                      break;
                    default:
                      this.$emit("ActionAfterCMDManagerOptions", this.objectCMD);
                      break;
                  }
                  break;
                case clientCommands.UnblockCommunication.name:
                  this.$store.commit("changeCommunication", true);
                  switch (this.route) {
                    case "/header/communication/block/fromUser":
                      this.$emit("ActionAfterUnblockCommunication");
                      break;
                    case "/components/reset/ResetValue":
                      this.$emit("ActionAfterCMDModuleReset", this.objectCMD);
                      break;
                  }
                  break;
                case clientCommands.BlockCommunication.name:
                  this.$store.commit("changeCommunication", false);
                  switch (this.route) {
                    case "/modules/bonus":
                      this.$emit("ActionAfterLoadingBonus");
                      break;
                    case "/modules/progressive":
                    case "/modules/progressive/Token":
                    case "/modules/progressive/MoveTerminal":
                      this.$emit("ActionAfterLoadingProgressive");
                      break;
                    case "/modules/configurations":
                      this.$emit("ActionAfterCMDConfig");
                      break;
                  }
                  break;
                case clientCommands.ServerRestart.name:
                  this.$emit("ActionAfterRestart", this.objectCMD);
                  break;
                case clientCommands.ServerStop.name:
                  closeWindow();
                  break;
                case clientCommands.UploadConfig.name:
                case clientCommands.DownloadConfig.name:
                case clientCommands.InstallConfig.name:
                case clientCommands.SaveBigOsSettings.name:
                case clientCommands.SendFile.name:
                case clientCommands.RunOsAdmin.name:
                case clientCommands.DisableEmailSender.name:
                  this.$emit("ActionAfterCMDConfig", this.objectCMD);
                  break;
                case clientCommands.CreateIni.name:
                case clientCommands.ValidateS2S.name:
                  this.$emit("ActionAfterCMDCreateIni", this.objectCMD);
                  break;
                case clientCommands.GetReport.name:
                  this.$emit("ActionAfterCMDGetReport", this.objectCMD);
                  break;
                case clientCommands.ProgMultiReset.name:
                case clientCommands.ResetBonus.name:
                  this.$emit("ActionAfterCMDModuleReset", this.objectCMD);
                  break;
                case clientCommands.CashierSignIn.name:
                case clientCommands.CashierBlock.name:
                case clientCommands.CashierUnblock.name:
                case clientCommands.CashierRegisterFill.name:
                case clientCommands.CashierRegisterDrop.name:
                case clientCommands.CashierAddNewPin.name:
                case clientCommands.CashierCheck.name:
                  this.$emit("ActionAfterCMDCashierOptions", this.objectCMD);
                  break;
                case clientCommands.CashierSignOut.name:
                  switch (this.route) {
                    case "/session/signOut":
                      this.$emit("ActionAfterCMDSession", this.objectCMD);
                      break;
                    default:
                      this.$emit("ActionAfterCMDCashierOptions", this.objectCMD);
                      break;
                  }
                  break;
                case clientCommands.CreateUser.name:
                case clientCommands.EditUser.name:
                  this.$emit("ActionAfterLoadingUser", this.objectCMD);
                  break;
                case clientCommands.PrintReport.name:
                  this.$store.commit("changeShowAlertPrintReport", false);
                  this.$emit(
                    "ActionAfterCMDManagerOptions",
                    this.objectCMD
                  );
                  break;
                case clientCommands.DeleteUser.name:
                case clientCommands.DeleteReport.name:
                  this.$emit("ActionAfterPageUser", this.objectCMD);

                  break;
                case clientCommands.LoggedIn.name:
                  this.$emit("ActionAfterLogin", this.objectCMD);
                  break;
                case clientCommands.LoggedOut.name:
                  switch (this.route) {
                    case "/modules/UserSettings":
                      this.$emit("ActionAfterLoadingUser", this.objectCMD);
                      break;
                    default:
                      this.$emit("ActionAfterSignOut", this.objectCMD);
                      break;
                  }

                  break;
                case clientCommands.CreateReport.name:
                case clientCommands.EditReport.name:
                  this.$emit("ActionAfterLoadingReport", this.objectCMD);
                  break;
                case clientCommands.CashierAccountInfo.name:
                case clientCommands.CashierCloseAccountRequest.name:
                case clientCommands.CashierCloseAccountOk.name:
                case clientCommands.CashierCloseAccountCancel.name:
                case clientCommands.CashierAccounts.name:
                case clientCommands.CashierReprintTicket.name:
                case clientCommands.CashierAddCredits.name:
                  this.$emit("ActionAfterTicket", this.objectCMD);
                  break;
                case clientCommands.CashierReport.name:
                  switch (this.route) {
                    case "/modules/configurations":
                      this.$emit("ActionAfterCMDConfig", this.objectCMD);
                      break;
                    default:
                      this.$emit(
                        "ActionAfterCMDManagerOptions",
                        this.objectCMD
                      );
                      break;
                  }
                  break;
                case clientCommands.CertificateReport.name:
                case clientCommands.ServerLogs.name:
                case clientCommands.ServerReports.name:
                case clientCommands.RequestConfig.name:
                case clientCommands.RequestBackup.name:
                case clientCommands.DoBackup.name:
                case clientCommands.DoRestore.name:
                case clientCommands.DeleteDatabase.name:
                case clientCommands.ResetBothConfigurations.name:
                case clientCommands.ResetClientConfiguration.name:
                case clientCommands.ResetServerConfiguration.name:
                case clientCommands.GenerateLicense.name:
                case clientCommands.AutomaticLicenseReq.name:
                case clientCommands.ValidateLicense.name:
                case clientCommands.CancelLicenseReq.name:
                case clientCommands.RequestLicense.name:
                case clientCommands.CheckLicenseReq.name:
                  this.$emit("ActionAfterCMDConfig", this.objectCMD);
                  break;
                case clientCommands.GetPenDriveList.name:
                  switch (this.route) {
                    case "/header/AddCash":
                      this.$emit("ActionAfterAddCashHeader", this.objectCMD);
                      break;
                    default:
                      this.$emit("ActionAfterCMDConfig", this.objectCMD);
                      break;
                  }
                  break;
                case clientCommands.CashierCopyFiles.name:
                case clientCommands.ServerLogsCopyFiles.name:
                case clientCommands.ServerReportsCopyFiles.name:
                case clientCommands.NavisionReportCopyFiles.name:
                case clientCommands.CopyCertificate.name:
                case clientCommands.ResendToQueue.name:
                  this.$emit("ActionAfterCMDCashierCopyReport", this.objectCMD);
                  break;
                case clientCommands.GenerateServerReports.name:
                case clientCommands.GenerateCsv.name:
                  this.$emit("ActionCMDAfterAccount", this.objectCMD);
                  break;
                case clientCommands.SetTerminalProgressive.name:
                  this.$emit("ActionAfterGroupMachines", this.objectCMD);
                  break;
                case clientCommands.SetLanguage.name:
                  this.$emit("ActionAfterLanguage", this.objectCMD);
                  break;
                case clientCommands.SendManualEmail.name:
                case clientCommands.SendTestEmail.name:
                case clientCommands.SetMaxFileSize.name:
                  this.$emit("ActionAfterEmail", this.objectCMD);
                  break;
                case clientCommands.HideMenu.name:
                  this.$store.commit("changeShowHideBtns", false);
                  topMost(true);
                  break;
                case clientCommands.ShowMenu.name:
                  this.$store.commit("changeShowHideBtns", true);
                  topMost(false);
                  break;
                case clientCommands.PaymentTerminal.name:
                case clientCommands.OpenTerminal.name:
                case clientCommands.DeleteTerminal.name:
                  switch (this.route) {
                    case "/modules/terminals/terminalOptions":
                      this.$emit("ActionAfterTerminalOptions", this.objectCMD);
                      break;
                    case "/modules/terminals/groupMachines":
                      this.$emit("ActionAfterGroupMachines", this.objectCMD);
                      break;
                  }
                  break;
                case clientCommands.ExportReport.name:
                  this.$emit("ActionAfterCMDExportReport", this.objectCMD);
                  break;
                case clientCommands.ForcePigBoom.name:
                case clientCommands.ForceMystery.name:
                case clientCommands.ForceMultiProgressiveLevel.name:
                case clientCommands.ClearForceLevelList.name:
                  this.$emit("ActionAfterDebug", this.objectCMD);
                  break;
                case clientCommands.ResetHashCode.name:
                case clientCommands.ValidateHashCode.name:
                case clientCommands.AddCash.name:
                case clientCommands.GetHashCode.name:
                  this.$emit("ActionAfterAddCash", this.objectCMD);
                  break;
                case clientCommands.RequestHashCodeFile.name:
                  this.$emit("ActionAfterAddCashHeader", this.objectCMD);
                  break;
                case clientCommands.GetPromotionsInfoAndPremiums.name:
                  this.$emit("ActionAfterMenuPromotions", this.objectCMD);
                  break;
                case clientCommands.ValidateCoupon.name:
                case clientCommands.UpdateCoupon.name:
                  this.$emit("ActionAfterValidateCoupon", this.objectCMD);
                  break;
                case clientCommands.ValidateErrorCode.name:
                case clientCommands.GenerateCoupon.name:
                case clientCommands.FindByVlt.name:
                case clientCommands.RefreshMachinesPrintCoupon.name:
                  this.$emit("ActionAfterGenerateCoupon", this.objectCMD);
                  break;
                case clientCommands.ValidateDigi.name:
                  this.$emit("ActionAfterDigi", this.objectCMD);
                  break;
                case clientCommands.MachineOperation.name:
                  this.$emit("ActionAfterMachineOperation", this.objectCMD);
                  break;
                case clientCommands.ShowLogs.name:
                  if (this.objectCMD.object != null) {
                    this.$emit("ActionAfterShowLogsHeader", this.objectCMD);
                  } else {
                    this.$emit("ActionAfterShowLogsLog", this.objectCMD);
                  }
                  break;
                case clientCommands.GlobalSettings.name:
                  this.$emit("ActionAfterCMDGlobalSettings", this.objectCMD);
                  break;
                case clientCommands.SaveFooter.name:
                case clientCommands.EditFooter.name:
                case clientCommands.CreatePlaylist.name:
                case clientCommands.EditPlaylist.name:
                  this.$emit("ActionAfterLoadingPlaylistSettings", this.objectCMD);
                  break;
                case clientCommands.DeleteFooter.name:
                case clientCommands.DeletePlaylist.name:
                case clientCommands.SetPanelData.name:
                case clientCommands.DisplayPanelName.name:
                case clientCommands.DeletePanel.name:
                case clientCommands.ResetFactoryPanel.name:
                case clientCommands.RestartPanel.name:
                case clientCommands.ShutdownPanel.name:
                  this.$emit("ActionAfterLoadingPanel", this.objectCMD);
                  break;
                case clientCommands.CouponView.name:
                case clientCommands.PrintTestCoupon.name:
                case clientCommands.PrintCoupon.name:
                  this.$emit("ActionAfterCouponManagement", this.objectCMD);
                  break;
                case clientCommands.GetOperatorServer.name:
                case clientCommands.DeleteOperator.name:
                  this.$emit("ActionAfterLoadingOperator", this.objectCMD);
                  break;
                case clientCommands.SelectOperator.name:
                  switch (this.route) {
                    case "/modules/dashboard":
                      this.$emit("ActionAfterLoadingDashboard", this.objectCMD);
                      break;
                    case "/modules/header":
                      this.$emit("ActionAfterSelectOperator", this.objectCMD);
                      break;
                    default:
                      break;
                  }
                  break;
                case clientCommands.ClientOptions.name:
                  this.$emit("ActionAfterClientOptions");
                  break;
                default:
                  console.log("Invalid CMD" + object.cmd);
                  break;
              }
              if (this.env == "development") {
                notify.message(
                  "foo",
                  this.translate("lblSucess"),
                  this.translate("messageServerFinishedCMD") +
                  " [" +
                  object.cmd +
                  "]",
                  "success",
                  500
                );
              }
            }
            this.close();
          } else {
            this.waitingForFinalCmd(object);
          }
        }
      } catch (e) {
        console.log("error in processFinalCMD() " + e);
        this.close();
      }
    },
  },
  created() {
    try {
      console.log(this.CMD);
      if (!this.isAxios) {
        this.sendCMD();
        SocketDriver.$on("getCmdAnswer", this.cmdAnswer);
        SocketDriver.$on("finishedCMD", this.finishedCMD);
      } else {
        if (this.JsonObject.isDownload) {
          downloadFile(this.JsonObject, this, this.countDown);
        } else {
          uploadFile(this.JsonObject.File, this.JsonObject.url, this, this.countDown);
        }
        this.waitingForRespAxios();
      }
    } catch (e) {
      console.log("error in created() " + e);
      this.close();
    }
  },
  beforeDestroy() {
    if (this.isAxios) {
      clearInterval(this.intervalWaitingAxios);
      console.log("/closed intervalWaitingAxios");
    } else {
      //
    }
    console.log("/closed LoadingCMD()");
  },
};
</script>


<style scoped src="@/css/styleModules.css"></style>
