import Vue from "vue"
import { clientCommands, webSocketStatus } from "@/js/ClientCommands.js";
import { decodeProtoMessage, encodeProtoMessage } from "@/js/protoBufDecoder.js";

let socket = null
let waitToReconnect = false;




const emitter = new Vue({
    methods: {
        send(message) {            
            try {
                let cmd = "";
                let data = "";
                let token = "";
                if (message.includes("|")) {
                    var split = message.split("|");
                    cmd = split[0] != null ? split[0] : "";
                    data = split[1] != null ? split[1] : "";
                    token = split[2] != null ? split[2] : "";                    
                } else {
                    cmd = message;
                }
                let finalMessage = encodeProtoMessage(cmd, null, data, token);
                if (socket != null && 1 === socket.readyState) {
                    socket.send(finalMessage);
                }
            } catch (e) {
                console.log("Error in send()" + e)
            }
        },
        sendBinary(cmd, data, token, url) {
            this.readFile(data, url, cmd, token);
        },
        readFile(file, url, cmd, token) {            
            let reader = new FileReader();
            reader.onload = function () {
                let arrayBuffer = new Uint8Array(reader.result);
                let finalMessage = encodeProtoMessage(cmd, arrayBuffer, url, token);
                if (socket != null && 1 === socket.readyState) {
                    socket.send(finalMessage);
                }
            }
            reader.readAsArrayBuffer(file);
        },

        sendFile(file) {
            socket.send(file);
        },

        returnStatus() {
            return socket == null ? webSocketStatus.CLOSED : socket.readyState
        },

        close() {
            if (socket != null && socket.readyState == webSocketStatus.OPEN) {
                socket.close();
            }
        },

        stopWebSocket() {
            if (socket != null) {
                waitToReconnect = true;
                socket.close();
                socket = null;
            }
        },
        startWebSocket(Ip) {
            console.log('webSocket Status [Checking]' + Ip);
            this.stopWebSocket();
            waitToReconnect = false;
            let attempts = 0;
            if (socket != null) {
                waitToReconnect = true;
                socket.close();
                socket = null;
                waitToReconnect = false;
            } else {
                console.log('webSocket Status [Starting]');
                if (this.intervalCheckStatus != null) {
                    clearInterval(this.intervalCheckStatus);
                }
                this.createInstance(Ip, attempts);
                this.intervalCheckStatus = setInterval(() => {
                    if (!waitToReconnect) {
                        switch (socket.readyState) {
                            case webSocketStatus.CONNECTING:
                                console.log('webSocket Status [Connecting]');
                                attempts = 0;
                                break;
                            case webSocketStatus.OPEN:
                                //console.log('webSocket Status [Open]');
                                attempts = 0;
                                break;
                            case webSocketStatus.CLOSING:
                                console.log('webSocket Status [Closing]');
                                attempts++;
                                break;
                            case webSocketStatus.CLOSED:
                                console.log('webSocket Status [Close] [' + attempts + ']');
                                emitter.$emit("noConnection", attempts);
                                attempts = 0;
                                if (socket != null) {
                                    socket.close();
                                    socket = null;
                                }
                                this.createInstance(Ip, attempts);
                                attempts++;
                                break;
                            default:
                                console.log('Invalid Status [' + socket.readyState + ']');
                                break;
                        }
                    }
                    /*
                    if (attempts == 10) {
                        console.log('10 Seconds without Connection');
                        emitter.$emit("noConnection", attempts);
                        attempts = 0;
                        socket.close();
                        socket = null;
                        clearInterval(intervalCheckStatus);
                    }
                    */
                }, 1000);
            }
        },

        createInstance(Ip, attempts) {
            let connectionString = window.origin.includes('https') ? 'wss://' + Ip + ':4999/ws' : 'ws://' + Ip + ':5000/ws'
            socket = new WebSocket(connectionString);
            socket.binaryType = 'arraybuffer'
            socket.onopen = function () {
                console.log("connected on " + connectionString);
                emitter.$emit("onConnection", attempts);
            };
            socket.onclose = function () {
                console.log("disconnected from " + connectionString);
            };
            socket.onmessage = function (evt) {
                let data = {}
                let binaryData = null;
                let buffer = new Uint8Array(evt.data);
                let finalMessage = decodeProtoMessage(buffer);
                if (finalMessage.Data != "") {
                    data = JSON.parse(finalMessage.Data);
                }
                if (finalMessage.BinaryData != null && finalMessage.BinaryData != undefined) {
                    binaryData = finalMessage.BinaryData;
                }
                try {
                    switch (finalMessage.CMD) {
                        case clientCommands.OpenSession.name:
                            emitter.$emit("openSessionCMD", data);
                            break;
                        case clientCommands.OpenSessionAll.name:
                            emitter.$emit("openSessionAllCMD", data);
                            break;
                        case clientCommands.CloseSession.name:
                            emitter.$emit("closeSessionCMD", data);
                            break;
                        case clientCommands.CloseSessionAll.name:
                            emitter.$emit("closeSessionAllCMD", data);
                            break;
                        case clientCommands.ServerData.name:
                            emitter.$emit("data", data);
                            break;
                        case clientCommands.Confirmation.name:
                            emitter.$emit("confirmation", true);
                            break;
                        case clientCommands.UnblockMachines.name:
                            emitter.$emit("unblockMachineCMD", data);
                            break;
                        case clientCommands.UnblockMachinesAll.name:
                            emitter.$emit("unblockMachinesAllCMD", data);
                            break;
                        case clientCommands.BlockMachines.name:
                            emitter.$emit("blockMachinesCMD", data);
                            break;
                        case clientCommands.BlockMachinesAll.name:
                            emitter.$emit("blockMachinesALLCMD", data);
                            break;
                        case clientCommands.CreateUser.name:
                            emitter.$emit("createUserCMD", data);
                            break;
                        case clientCommands.EditUser.name:
                            emitter.$emit("editUserCMD", data);
                            break;
                        case clientCommands.DeleteUser.name:
                            emitter.$emit("deleteUserCMD", data);
                            break;
                        case clientCommands.CreateReport.name:
                            emitter.$emit("createReportCMD", data);
                            break;
                        case clientCommands.EditReport.name:
                            emitter.$emit("editReportCMD", data);
                            break;
                        case clientCommands.DeleteReport.name:
                            emitter.$emit("deleteReportCMD", data);
                            break;
                        case clientCommands.LoggedIn.name:
                            emitter.$emit("loggedInCMD", data);
                            break;
                        case clientCommands.LoggedOut.name:
                            emitter.$emit("loggedOutCMD", data);
                            break;
                        case clientCommands.CreateToken.name:
                            emitter.$emit("getTokenCMD", data);
                            break;
                        case clientCommands.ValidateToken.name:
                            emitter.$emit("validateTokenCMD", data);
                            break;
                        case clientCommands.BlockCommunication.name:
                            emitter.$emit("blockCommunicationCMD", data);
                            break;
                        case clientCommands.BlockCommunicationAll.name:
                            emitter.$emit("blockCommunicationAllCMD", data);
                            break;
                        case clientCommands.UnblockCommunication.name:
                            emitter.$emit("unblockCommunicationCMD", data);
                            break;
                        case clientCommands.UnblockCommunicationAll.name:
                            emitter.$emit("unblockCommunicationAllCMD", data);
                            break;
                        case clientCommands.ServerRestart.name:
                            emitter.$emit("serverRestartCMD", data);
                            break;
                        case clientCommands.ServerStop.name:
                            emitter.$emit("serverCloseCMD", data);
                            break;
                        case clientCommands.Login.name:
                            emitter.$emit("loginCMD", data);
                            break;
                        case clientCommands.DownloadConfig.name:
                            emitter.$emit("downloadConfigCMD", data);
                            break;
                        case clientCommands.SendFile.name:
                            emitter.$emit("sendFileCMD", data);
                            break;
                        case clientCommands.UploadConfig.name:
                            emitter.$emit("uploadConfigCMD", data);
                            break;
                        case clientCommands.InstallConfig.name:
                            emitter.$emit("installConfigCMD", data);
                            break;
                        case clientCommands.RequestConfig.name:
                            emitter.$emit("requestConfigCMD", data);
                            break;
                        case clientCommands.GetPenDriveList.name:
                            emitter.$emit("getPenDriveListCMD", data);
                            break;
                        case clientCommands.CreateIni.name:
                            emitter.$emit("createIniCMD", data);
                            break;
                        case clientCommands.LoginRequired.name:
                            emitter.$emit("loginRequiredCMD", data);
                            break;
                        case clientCommands.ForcedDisconnection.name:
                            emitter.$emit("forcedDisconnectionCMD", data);
                            break;
                        case clientCommands.UpdateListUsers.name:
                            emitter.$emit("updateListUsersCMD", data);
                            break;
                        case clientCommands.ReportManagerData.name:
                            emitter.$emit("reportManagerDataCMD", data);
                            break;
                        case clientCommands.GetReport.name:
                            emitter.$emit("getReportCMD", data);
                            break;
                        case clientCommands.FinishGetReport.name:
                            break;
                        case clientCommands.ProgMultiReset.name:
                            emitter.$emit("progMultiResetCMD", data);
                            break;
                        case clientCommands.ResetBonus.name:
                            emitter.$emit("bonusResetCMD", data);
                            break;
                        case clientCommands.CashierSignIn.name:
                            emitter.$emit("cashierSignIn", data);
                            break;
                        case clientCommands.CashierSignOut.name:
                            emitter.$emit("cashierSignOut", data);
                            break;
                        case clientCommands.CashierBlock.name:
                            emitter.$emit("cashierUnblock", data);
                            break;
                        case clientCommands.CashierUnblock.name:
                            emitter.$emit("cashierBlock", data);
                            break;
                        case clientCommands.CashierAccountInfo.name:
                            emitter.$emit("cashierAccountInfo", data);
                            break;
                        case clientCommands.CashierCloseAccountRequest.name:
                            emitter.$emit("cashierCloseAccountRequest", data);
                            break;
                        case clientCommands.CashierCloseAccountOk.name:
                            emitter.$emit("cashierCloseAccountOk", data);
                            break;
                        case clientCommands.CashierCloseAccountCancel.name:
                            emitter.$emit("cashierCloseAccountCancel", data);
                            break;
                        case clientCommands.CashierRegisterFill.name:
                            emitter.$emit("cashierRegisterFill", data);
                            break;
                        case clientCommands.CashierRegisterDrop.name:
                            emitter.$emit("cashierRegisterDrop", data);
                            break;
                        case clientCommands.CashierAddNewPin.name:
                            emitter.$emit("cashierAddNewPin", data);
                            break;
                        case clientCommands.CashierAccounts.name:
                            emitter.$emit("cashierAccounts", data);
                            break;
                        case clientCommands.CashierReprintTicket.name:
                            emitter.$emit("cashierReprintTicket", data);
                            break;
                        case clientCommands.CashierReport.name:
                            emitter.$emit("cashierReport", data);
                            break;
                        case clientCommands.ServerLogs.name:
                            emitter.$emit("serverLogs", data);
                            break;
                        case clientCommands.ServerReports.name:
                            emitter.$emit("serverReportsCMD", data);
                            break;
                        case clientCommands.CashierCopyFiles.name:
                            emitter.$emit("cashierCopyFiles", data, binaryData);
                            break;
                        case clientCommands.ServerLogsCopyFiles.name:
                            emitter.$emit("serverLogsCopyFiles", data, binaryData);
                            break;
                        case clientCommands.ServerReportsCopyFiles.name:
                            emitter.$emit("serverReportsCopyFilesCMD", data, binaryData);
                            break;
                        case clientCommands.CertificateReport.name:
                            emitter.$emit("certificateReport", data);
                            break;
                        case clientCommands.CopyCertificate.name:
                            emitter.$emit("copyCertificate", data, binaryData);
                            break;
                        case clientCommands.CashierAddCredits.name:
                            emitter.$emit("cashierAddCredits", data);
                            break;
                        case clientCommands.GenerateServerReports.name:
                            emitter.$emit("generateServerReports", data);
                            break;
                        case clientCommands.GenerateCsv.name:
                            emitter.$emit("generateCSV", data);
                            break;
                        case clientCommands.SetTerminalProgressive.name:
                            emitter.$emit("setTerminalProgressive", data);
                            break;
                        case clientCommands.SetLanguage.name:
                            emitter.$emit("setLanguage", data);
                            break;
                        case clientCommands.ExportReport.name:
                            emitter.$emit("exportReport", data, binaryData);
                            break;
                        case clientCommands.SendManualEmail.name:
                            emitter.$emit("sendManualEmail", data);
                            break;
                        case clientCommands.SendTestEmail.name:
                            emitter.$emit("sendTestEmail", data);
                            break;
                        case clientCommands.SetMaxFileSize.name:
                            emitter.$emit("setMaxFileSizeCMD", data);
                            break;
                        case clientCommands.HideMenu.name:
                            emitter.$emit("hideMenu", data);
                            break;
                        case clientCommands.ShowMenu.name:
                            emitter.$emit("showMenu", data);
                            break;
                        case clientCommands.PaymentTerminal.name:
                            emitter.$emit("paymentTerminal", data);
                            break;
                        case clientCommands.OpenTerminal.name:
                            emitter.$emit("openTerminal", data);
                            break;
                        case clientCommands.DeleteTerminal.name:
                            emitter.$emit("deleteTerminal", data);
                            break;
                        case clientCommands.ResendToQueue.name:
                            emitter.$emit("resendToQueue", data);
                            break;
                        case clientCommands.DoBackup.name:
                            emitter.$emit("doBackupCMD", data, binaryData);
                            break;
                        case clientCommands.DoRestore.name:
                            emitter.$emit("doRestoreCMD", data);
                            break;
                        case clientCommands.DeleteDatabase.name:
                            emitter.$emit("deleteDatabaseCMD", data);
                            break;
                        case clientCommands.RequestBackup.name:
                            emitter.$emit("requestBackup", data);
                            break;
                        case clientCommands.ForcePigBoom.name:
                            emitter.$emit("forcePigBoom", data);
                            break;
                        case clientCommands.ForceMystery.name:
                            emitter.$emit("forceMystery", data);
                            break;
                        case clientCommands.ForceMultiProgressiveLevel.name:
                            emitter.$emit("forceMultiProgressiveLevel", data);
                            break;
                        case clientCommands.ClearForceLevelList.name:
                            emitter.$emit("clearForceLevelList", data);
                            break;
                        case clientCommands.GetHashCode.name:
                            emitter.$emit("getHashCode", data);
                            break;
                        case clientCommands.ResetHashCode.name:
                            emitter.$emit("resetHashCode", data);
                            break;
                        case clientCommands.ValidateHashCode.name:
                            emitter.$emit("validateHashCode", data);
                            break;
                        case clientCommands.AddCash.name:
                            emitter.$emit("addCash", data);
                            break;
                        case clientCommands.RequestHashCodeFile.name:
                            emitter.$emit("requestHashCodeFile", data);
                            break;
                        case clientCommands.GetPromotionsInfoAndPremiums.name:
                            emitter.$emit("getPromotionsInfoAndPremiums", data);
                            break;
                        case clientCommands.ValidateCoupon.name:
                            emitter.$emit("validateCoupon", data);
                            break;
                        case clientCommands.ValidateS2S.name:
                            emitter.$emit("validateS2S", data);
                            break;
                        case clientCommands.UpdateCoupon.name:
                            emitter.$emit("updateCoupon", data);
                            break;
                        case clientCommands.ResetServerConfiguration.name:
                            emitter.$emit("resetServerConfiguration", data);
                            break;
                        case clientCommands.ResetClientConfiguration.name:
                            emitter.$emit("resetIpConfiguration", data);
                            break;
                        case clientCommands.ResetBothConfigurations.name:
                            emitter.$emit("resetBothConfigurations", data);
                            break;
                        case clientCommands.ValidateErrorCode.name:
                            emitter.$emit("validateErrorCode", data);
                            break;
                        case clientCommands.GenerateCoupon.name:
                            emitter.$emit("generateCoupon", data);
                            break;
                        case clientCommands.FindByVlt.name:
                            emitter.$emit("findByVlt", data);
                            break;
                        case clientCommands.RefreshMachinesPrintCoupon.name:
                            emitter.$emit("refreshMachinesPrintCoupon", data);
                            break;
                        case clientCommands.ValidateDigi.name:
                            emitter.$emit("validateDigi", data);
                            break;
                        case clientCommands.MachineOperation.name:
                            emitter.$emit("machineOperation", data);
                            break;
                        case clientCommands.HasEmailsToSend.name:
                            emitter.$emit("hasEmailsToSendCMD", data);
                            break;
                        case clientCommands.SaveBigOsSettings.name:
                            emitter.$emit("saveBigOsSettings", data);
                            break;
                        case clientCommands.ShowLogs.name:
                            emitter.$emit("showLogs", data);
                            break;
                        case clientCommands.CashierCheck.name:
                            emitter.$emit("cashierCheckCMD", data);
                            break;
                        case clientCommands.PrintReport.name:
                            emitter.$emit("printReportCMD", data);
                            break;
                        case clientCommands.VoucherViewer.name:
                            emitter.$emit("voucherViewerCMD", data);
                            break;
                        case clientCommands.GistraReport.name:
                            emitter.$emit("gistraReportCMD", data);
                            break;
                        case clientCommands.NavisionReport.name:
                            emitter.$emit("navisionReportCMD", data);
                            break;
                        case clientCommands.NavisionReportCopyFiles.name:
                            emitter.$emit("navisionReportCopyFilesCMD", data);
                            break;
                        case clientCommands.UpdateDatabase.name:
                            emitter.$emit("updateDatabaseCMD", data);
                            break;
                        case clientCommands.GlobalSettings.name:
                            emitter.$emit("globalSettingsCMD", data);
                            break;
                        case clientCommands.DefaultNotification.name:
                            emitter.$emit("defaultNotificationCMD", data);
                            break;
                        case clientCommands.RunOsAdmin.name:
                            emitter.$emit("runOsAdminCMD", data);
                            break;
                        case clientCommands.SaveFooter.name:
                            emitter.$emit("saveFooterCMD", data);
                            break;
                        case clientCommands.EditFooter.name:
                            emitter.$emit("editFooterCMD", data);
                            break;
                        case clientCommands.DeleteFooter.name:
                            emitter.$emit("deleteFooterCMD", data);
                            break;
                        case clientCommands.DeletePlaylist.name:
                            emitter.$emit("deletePlaylistCMD", data);
                            break;
                        case clientCommands.DisplayPanelName.name:
                            emitter.$emit("displayPanelNameCMD", data);
                            break;
                        case clientCommands.CreatePlaylist.name:
                            emitter.$emit("createPlaylistCMD", data);
                            break;
                        case clientCommands.EditPlaylist.name:
                            emitter.$emit("editPlaylistCMD", data);
                            break;
                        case clientCommands.SetPanelData.name:
                            emitter.$emit("setPanelDataCMD", data);
                            break;
                        case clientCommands.DeletePanel.name:
                            emitter.$emit("deletePanelCMD", data);
                            break;
                        case clientCommands.ResetFactoryPanel.name:
                            emitter.$emit("resetFactoryPanelCMD", data);
                            break;
                        case clientCommands.RestartPanel.name:
                            emitter.$emit("restartPanelCMD", data);
                            break;
                        case clientCommands.ShutdownPanel.name:
                            emitter.$emit("shutdownPanelCMD", data);
                            break;
                        case clientCommands.ConnectionPermission.name:
                            emitter.$emit("connectionPermissionCMD", data);
                            break;
                        case clientCommands.DisableEmailSender.name:
                            emitter.$emit("disableEmailSender", data);
                            break;
                        case clientCommands.ToggleTouchscreen.name:
                            emitter.$emit("toggleTouchscreen", data);
                            break;
                        case clientCommands.GenerateLicense.name:
                            emitter.$emit("generateLicense", data, binaryData);
                            break;
                        case clientCommands.AutomaticLicenseReq.name:
                            emitter.$emit("automaticLicenseReq", data);
                            break;
                        case clientCommands.ValidateLicense.name:
                            emitter.$emit("validateLicense", data);
                            break;
                        case clientCommands.CancelLicenseReq.name:
                            emitter.$emit("cancelLicenseReq", data);
                            break;
                        case clientCommands.RequestLicense.name:
                            emitter.$emit("requestLicense", data);
                            break;
                        case clientCommands.CheckLicenseReq.name:
                            emitter.$emit("checkLicenseReq", data);
                            break;
                        case clientCommands.CouponView.name:
                            emitter.$emit("couponViewCMD", data);
                            break;
                        case clientCommands.PrintTestCoupon.name:
                            emitter.$emit("printTestCouponCMD", data);
                            break;
                        case clientCommands.PrintCoupon.name:
                            emitter.$emit("printCouponCMD", data);
                            break;
                        case clientCommands.GetOperatorServer.name:
                            emitter.$emit("getOperatorServerCMD", data);
                            break;
                        case clientCommands.SelectOperator.name:
                            emitter.$emit("selectOperatorCMD", data);
                            break;
                        case clientCommands.DeleteOperator.name:
                            emitter.$emit("deleteOperatorCMD", data);
                            break;
                        case clientCommands.ClientOptions.name:
                            emitter.$emit("clientOptionsCMD", data);
                            break;
                        default:
                            console.log('Invalid CMD[' + finalMessage.CMD + ']');
                            break;
                    }
                } catch (e) {
                    console.log("Error in socket.onmessage " + e.message)
                }
            };
            socket.onerror = function (evt) {
                console.log('<span style="color: red;">ERROR:</span> ' + evt.data);
            };
        },

        onMessage() {
            //
        },

        onError(evt) {
            console.log('<span style="color: red;">ERROR:</span> ' + evt.data);
        },

        onOpen() {
            console.log("CONNECTED :5050");
        },

        onClose() {
            console.log("#####   ON CLOSE DISCONNECTED");
        }
    }
});

//Vue.use(emitter.startWebSocket);

export default emitter