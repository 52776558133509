<template>
  <form action="javascript:void(0);" style="overflow-y: hidden">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ translate("btnReset") }} [ {{ objBackup.Name }} ]
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template #default="props">
            <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage" :countDown="countDown"
              :JsonObject="objReset" @ActionAfterCMDModuleReset="afterLoading" :route="route">
            </LoadingCMD>
          </template>
        </b-modal>

        <b-modal v-model="showModalConfirmation" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
          :can-cancel="false">
          <template>
            <section>
              <form action="javascript:void(0);">
                <div class="modal-card" style="width: auto">
                  <header class="modal-card-head">
                    <p class="modal-card-title">
                      {{ translate("confirmReset") }}
                    </p>
                  </header>
                  <section class="modal-card-body">
                    <b-message type="is-info" style="text-align: center">
                      <div v-if="obj.isMulti">
                        <b-table :data="objBackup.Levels" ref="table" aria-next-label="Next page"
                          aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                          striped custom-row-key="id" default-sort="LevelName">
                          <template #empty>
                            <div class="has-text-centered">
                              <h1>
                                <span class="tag is-danger">
                                  {{ translate("lblNoDataFound") }}
                                </span>
                              </h1>
                            </div>
                          </template>
                          <b-table-column field="Level Name" centered :label="translate('columnLevel') +
                            ' ' +
                            translate('columnName')
                            " v-slot="props" header-class="is-sticky-column-one" width="200" style="padding-top: 14px">
                            {{ props.row.Level }}
                          </b-table-column>
                          <b-table-column field="Value" centered :label="translate('columnValue')" v-slot="props"
                            header-class="is-sticky-column-one" width="200" style="padding-top: 14px">
                            <b-input-group :prepend="getCurrency()" v-if="props.row.Reset">
                              <money class="form-control" id="minValueGrand" type="text" name="minValueGrand"
                                v-bind="money" v-validate="'numeric'" v-model="props.row.NewValue"
                                :placeholder="translate('lblNewValue')" maxlength="11" disabled :precision="2"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                            </b-input-group>
                            <b-input disabled v-else :placeholder="translate('lblNotChanged')"></b-input>
                          </b-table-column>
                        </b-table>
                      </div>
                      <div v-else>
                        <div class="col-sm-12">
                          <div v-if="objBackup.ConfirmValue == ''">
                            <b-field :label="objBackup.Name +
                              ' ' +
                              translate('lblValueWillBe')
                              " label-position="on-border">
                              <b-input-group :prepend="getCurrency()">
                                <money class="form-control" id="minValueGrand" type="text" name="minValueGrand"
                                  v-bind="money" v-validate="'numeric'" v-model="objBackup.Value"
                                  :placeholder="translate('lblNewValue')" maxlength="11" disabled
                                  :max="objBackup.MaxValue" :min="objBackup.IniValue" :precision="this.precision"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                              </b-input-group>
                            </b-field>
                          </div>
                          <b-field disabled v-else :label="objBackup.Name +
                            ' ' +
                            translate('lblValueWillBe') +
                            ' ' +
                            objBackup.ConfirmValue
                            ">
                          </b-field>
                        </div>

                      </div>
                      <br />
                      <div style="float: right">
                        <b-button style="margin-right: 10px" @click.prevent="showModalConfirmation = false"
                          :label="translate('btnCancel')" icon-left=".icons-white icons-times"
                          icon-pack="icons"></b-button>
                        <b-button @click.prevent="save()" :label="translate('btnConfirm')" type="is-success"
                          icon-left=".icons-white icons-check" icon-pack="icons"></b-button>
                      </div>
                    </b-message>
                  </section>
                </div>
              </form>
            </section>
          </template>
        </b-modal>

        {{ obj.type }}
        <div v-if="!obj.isMulti">
          <section>
            <Message v-if="errorResetValue" type="is-danger" :message="message" :isList="false" />
            <b-field :label="translate('lblNewValue')" style="padding-bottom: 10px">
              <div class="col-sm-1">
                <button class="btn btn-outline-secondary" style="height: 39px" disabled>
                  <i class="icons-1x icons-sack-money" />
                </button>
              </div>
              <div class="col-sm-10">
                <b-input-group :prepend="getCurrency()">
                  <money class="form-control" id="minValueGrand" type="text" name="minValueGrand" v-bind="money"
                    v-validate="'numeric'" v-model="objBackup.Value" :placeholder="translate('lblNewValue')"
                    maxlength="11" :max="objBackup.MaxValue" :min="objBackup.IniValue" :precision="this.precision"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                </b-input-group>
              </div>
              <div class="col-sm-1"><button class="btn btn-outline-secondary" type="button"
                  @click.prevent="processShowKeyboard('newValue')">
                  <i class="icons-1x icons-keyboard" />
                </button></div>

            </b-field>


            <div>

              <b-field :label="translate('lblResetType')">
              </b-field>
              <div class="row">
                <div class="col-sm-7">
                  <b-radio v-model="resetTypeUser" size="is-small" :native-value="resetType.AdjustmentValue">
                    {{ translate('lblAdjustmentSystem') }}
                  </b-radio>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-7">
                  <b-radio v-model="resetTypeUser" size="is-small" :native-value="resetType.PaymentNoConnection">
                    {{ translate('lblPayNoConnection') }}
                  </b-radio>
                </div>
                <div class="col-sm-4">
                  <b-input-group :prepend="getCurrency()">
                    <money class="form-control" id="minValueGrand" type="text" name="minValueGrand" v-bind="money"
                      :disabled="resetTypeUser!= resetType.PaymentNoConnection" v-validate="'numeric'"
                      v-model="objBackup.PaidValue" :placeholder="translate('lblNewValue')" maxlength="11"
                      :max="objBackup.MaxValue" :min="objBackup.IniValue" :precision="this.precision"                      
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                  </b-input-group>

                </div>
                <div class="col-sm-1">
                  <button class="btn btn-outline-secondary" type="button" :disabled="resetTypeUser != resetType.PaymentNoConnection"  @click.prevent="processShowKeyboard('paidValue')">
                    <i class="icons-1x icons-keyboard" />
                  </button>
                </div>
              </div>
              <br />
            </div>
            <div v-if="obj.typeModule == 0 && this.user.countryCode == countries.Mexico
              ">
              <br />
              <b-checkbox v-model="objBackup.bResetReserve" />
              <b-field :label="translate('lblReserveValue')" style="padding-bottom: 10px">
                <div class="col-sm-1">
                  <button class="btn btn-outline-secondary" style="height: 39px" disabled>
                    <i class="icons-1x icons-sack-money" />
                  </button>
                </div>
                <div class="col-sm-11">
                  <b-input-group :prepend="getCurrency()" :onmouseover="showKeyboardNewValue = true">
                    <money class="form-control" id="valueReserve" type="text" name="valueReserve" v-bind="money"
                      v-validate="'numeric'" v-model="objBackup.ReserveValue" :placeholder="translate('lblReserveValue')"
                      maxlength="11" :disabled="!objBackup.bResetReserve" :max="objBackup.ReserveMax" :min="0"
                      @focus="(showKeyboardNewValue = false), (showKeyboardPaidValue = true)" :precision="this.precision"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      :onmouseover="showKeyboardNewValue = true" />
                  </b-input-group>
                </div>
              </b-field>
            </div>
          </section>
          <div class="mb-2" v-if="showKeyboard" style="width: 100%; margin-top: 0.25rem">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress"
              :input="showKeyboardNewValue ? objBackup.Value.toString() : objBackup.PaidValue.toString()"
              :isNumeric="true" :pin="11" />
          </div>
          <footer class="modal-card-foot">
            <div style="padding-botton: -4px">

              <b-button style="margin-right: 10px" @click="$emit('close')" :label="translate('btnCancel')"
                icon-left=".icons-white icons-times" icon-pack="icons" />
              <b-button @click="processSave()" :label="translate('btnReset')" type="is-primary"
                :disabled="resetTypeUser == undefined" icon-left=".icons-white icons-check" icon-pack="icons" />

            </div>
          </footer>

        </div>
        <div v-else>
          <section>
            <Message v-if="errorResetValue" type="is-danger" :message="message" :isList="false" />
            <br />
            <b-table :data="objBackup.Levels" ref="table" aria-next-label="Next page" aria-previous-label="Previous page"
              aria-page-label="Page" aria-current-label="Current page" striped custom-row-key="id"
              default-sort="LevelName">
              <template #empty>
                <div class="has-text-centered">
                  <h1>
                    <span class="tag is-danger">
                      {{ translate("lblNoDataFound") }}
                    </span>
                  </h1>
                </div>
              </template>
              <b-table-column field="Level Name" centered :label="translate('columnLevel') + ' ' + translate('columnName')
                " v-slot="props" header-class="is-sticky-column-one" width="200" style="padding-top: 14px">
                {{ props.row.Level }}
              </b-table-column>
              <b-table-column v-if="this.user.countryCode == countries.Philippines" field="Current Value" centered
                :label="translate('btnCurrent') + ' ' + translate('lblValue')" v-slot="props"
                header-class="is-sticky-column-one" width="200" style="padding-top: 14px">
                <money class="form-control" type="text" v-bind="money" v-validate="'numeric'" v-model="props.row.Value"
                  :value="props.row.Value" maxlength="11" required :disabled="true" :max="props.row.MaxValue"
                  :min="props.row.IniValue" :precision="0"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
              </b-table-column>
              <b-table-column field="Change" centered :label="translate('columnChange')" v-slot="props"
                header-class="is-sticky-column-one" width="200" style="padding-top: 14px">
                <b-checkbox v-model="props.row.Reset" @click.native="openKeyboardReset('', props.row.Level)" />
              </b-table-column>
              <b-table-column v-if="this.user.countryCode != countries.Philippines" field="Current Value" centered
                :label="translate('btnCurrent') + ' ' + translate('lblValue')" v-slot="props"
                header-class="is-sticky-column-one" width="200" style="padding-top: 14px">
                <money class="form-control" type="text" v-bind="money" v-validate="'numeric'" v-model="props.row.Value"
                  :value="props.row.Value" maxlength="11" required :disabled="true" :max="props.row.MaxValue"
                  :min="props.row.IniValue" :precision="2"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
              </b-table-column>
              <b-table-column v-if="this.user.countryCode == countries.Philippines" :field="translate('lblNewValue')"
                centered :label="translate('lblNewValue')" v-slot="props" header-class="is-sticky-column-one" width="200">
                <money class="form-control" v-model="props.row.NewValue" :value="props.row.NewValue"
                  :placeholder="translate('lblNewValue')" maxlength="11" required :disabled="!props.row.Reset"
                  :max="props.row.MaxValue" :min="props.row.IniValue" :precision="0"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
              </b-table-column>
              <b-table-column v-if="this.user.countryCode != countries.Philippines" :field="translate('lblNewValue')"
                centered :label="translate('lblNewValue')" v-slot="props" header-class="is-sticky-column-one" width="200">
                <money class="form-control" v-model="props.row.NewValue" :value="props.row.NewValue"
                  :placeholder="translate('lblNewValue')" maxlength="11" required :disabled="!props.row.Reset"
                  :max="props.row.MaxValue" :min="props.row.IniValue" :precision="2"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
              </b-table-column>
              <b-table-column field="actions" :label="translate('columnActions')" width="300" centered v-slot="props"
                header-class="is-sticky-column-one">
                <button :class="[
                  {
                    'btn btn-outline-success': props.row.Level === levelType,
                  },
                  { 'btn btn-outline-light': props.row.Level !== levelType },
                ]" type="button" @click.prevent="
  openKeyboardReset(props.row.NewValue, props.row.Level)
  " :disabled="!props.row.Reset">
                  <i class="icons-lg0 icons-keyboard" />
                </button>
              </b-table-column>
              <template #footer>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-2" v-if="showKeyboard" style="width: 100%; margin-top: 0.25rem">
                      <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :key="levelType"
                        :input="inputLevel.toString()" :isNumeric="true" :pin="11" />
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </template>
            </b-table>
            <footer class="modal-card-foot">
              <b-button :label="translate('btnCancel')" @click="$emit('close')" style="margin-right: 10px"
                icon-left=".icons-white icons-times" icon-pack="icons" />
              <b-button :label="translate('btnReset')" @click="processSave()" :disabled="disabledResetBtn()"
                type="is-primary" icon-left=".icons-white icons-check" icon-pack="icons" />
            </footer>
          </section>
        </div>
      </section>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import { Money } from "v-money";
import { countries } from "@/mixins/countryCode.js";
import { clientCommands } from "@/js/ClientCommands";
import { typesModules, resetType } from "@/mixins/enums";
import { returnCurrency } from "@/mixins/countryCode.js";
import LoadingCMD from "@/components/processCMD/LoadingCMD";
import validateField from "@/js/validateField";
import SimpleKeyboard from "@/components/keyboard/SimpleKeyboard.vue";
import Message from "@/components/message/Message.vue";
import maskara from "@/mixins/maskara.js";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  name: "Token",
  components: { LoadingCMD, Money, SimpleKeyboard, Message },
  mixins: [maskara, en, es, nor, swe],
  computed: mapState(["communication", "user", "lang"]),
  data() {
    return {
      cmd: "",
      countDown: 10,
      showLoadingCMD: false,
      sendingCMDMessage: "",
      showKeyboardValueProg: false,
      showModalConfirmation: false,
      objBackup: {},
      objReset: {},
      errorResetValue: false,
      message: "",
      isAfterReset: false,
      countries: countries,
      precision: 2,
      strMinValueMega: "",
      strMaxValueMega: "",
      strMaxValueReserve: "",
      strMinValueGrand: "",
      strMaxValueGrand: "",
      strMinValueMajor: "",
      strMaxValueMajor: "",
      strMinValueMinor: "",
      strMaxValueMinor: "",
      showKeyboard: false,
      inputLevel: "",
      levelType: "",
      resetType: resetType,
      showKeyboardPaidValue: false,
      showKeyboardNewValue: false,
      resetTypeUser: 1
    };
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    getCurrency() {
      return returnCurrency(this.user.countryCode);
    },

    onChange(input) {      
      if (this.levelType == "") {
        if (this.showKeyboardPaidValue) {
          this.objBackup.PaidValue = input;
        } else {
          this.objBackup.Value = input;
        }
      } else {
        this.objBackup.Levels.forEach((level) => {
          if (level.Level == this.levelType) {
            level.NewValue = input;
          }
        });
      }
    },
    onKeyPress(button) {
      console.log("button", button);      
      if (button === "{clear}") {
        if (this.levelType == "") {
          if (this.showKeyboardPaidValue) {
            this.objBackup.PaidValue = "";
          } else {
            this.objBackup.Value = "";
          }

        } else {
          this.objBackup.Levels.forEach((level) => {
            if (level.Level == this.levelType) {
              level.NewValue = "";
            }
          });
        }
      }
    },
    onInputChange(input) {      
      if (this.levelType == "") {

        if (this.showKeyboardPaidValue) {
          this.objBackup.PaidValue = input;
        } else {
          this.objBackup.Value = input;
        }
      } else {
        this.objBackup.Levels.forEach((level) => {
          if (level.Level == this.levelType) {
            level.NewValue = input;
          }
        });
      }
    },

    formatValue(value, level) {
      level.NewValue = validateField.formatNumberCen(value);
    },

    processSave() {
      try {
        this.errorResetValue = false;
        this.message = "";
        let errorMegaRes = false;
        let message = "";



        if (
          parseFloat(this.objBackup.Value) <
          parseFloat(this.objBackup.IniValue) ||
          parseFloat(this.objBackup.Value) > parseFloat(this.objBackup.MaxValue)
        ) {
          errorMegaRes = true;
          message =
            this.translate("messageErrorValue") +
            " " +
            this.strMinValueMega +
            " " +
            this.translate("to") +
            " " +
            this.strMaxValueMega;
        }
        if (
          parseFloat(this.objBackup.ReserveValue) < parseFloat(0) ||
          parseFloat(this.objBackup.ReserveValue) > parseFloat(this.objBackup.ReserveMax)
        ) {
          errorMegaRes = true;
          if (message != "") {
            message += "\n ";
          }
          message +=
            this.translate("messageErrorReserveValue") +
            " " +
            0 +
            " " +
            this.translate("to") +
            " " +
            this.strMaxValueReserve;
        }
        let errorLevel = false;
        if (this.objBackup.isMulti) {
          this.objBackup.Levels.forEach((element) => {
            if (element.Level === "Grand" && element.Reset) {
              if (
                parseFloat(element.MaxValue) <
                parseFloat(element.NewValue) ||
                parseFloat(element.IniValue) > parseFloat(element.NewValue)
              ) {
                errorLevel = true;
                if (message != "") {
                  message += "\n ";
                }
                message +=
                  this.translate("messageErrorGrandValue") +
                  " " +
                  this.strMinValueGrand +
                  " " +
                  this.translate("to") +
                  " " +
                  this.strMaxValueGrand +
                  ".";
              }

              if (!errorLevel) {
                this.objReset.ResetGrand = true;
                this.objReset.GrandValue = element.NewValue;
              }
            }
            if (element.Level === "Major" && element.Reset) {
              if (
                parseFloat(element.MaxValue) <
                parseFloat(element.NewValue) ||
                parseFloat(element.IniValue) > parseFloat(element.NewValue)
              ) {
                errorLevel = true;
                if (message != "") {
                  message += "\n ";
                }
                message +=
                  this.translate("messageErrorMajorValue") +
                  " " +
                  this.strMinValueMajor +
                  " " +
                  this.translate("to") +
                  " " +
                  this.strMaxValueMajor +
                  ".";
              }

              if (!errorLevel) {
                this.objReset.ResetMajor = true;
                this.objReset.MajorValue = element.NewValue;
              }
            }
            if (element.Level === "Minor" && element.Reset) {
              if (
                parseFloat(element.MaxValue) <
                parseFloat(element.NewValue) ||
                parseFloat(element.IniValue) > parseFloat(element.NewValue)
              ) {
                errorLevel = true;
                if (message != "") {
                  message += "\n ";
                }
                message +=
                  this.translate("messageErrorMinorValue") +
                  " " +
                  this.strMinValueMinor +
                  " " +
                  this.translate("to") +
                  " " +
                  this.strMaxValueMinor +
                  ".";
              }

              if (!errorLevel) {
                this.objReset.ResetMinor = true;
                this.objReset.MinorValue = element.NewValue;
              }
            }
          });
        }
        if (errorMegaRes || errorLevel) {
          this.errorResetValue = true;
          this.message = message;
        } else {
          if (this.objBackup.isMulti) {
            this.objBackup.Levels.forEach((aux) => {
              this.checkValues(aux);
            });
          } else {
            this.checkValues(this.objBackup);
          }

          this.showModalConfirmation = true;
          if(this.resetTypeUser == this.resetType.PaymentNoConnection) {
            this.openModalWarningResetMega();
          }
        }
      } catch (e) {
        console.log("Error in processSave() " + e);
      }
    },

    checkValues(objAux) {
      try {
        let ret = this.translate("lblNotChanged");
        if (objAux.isMulti) {
          this.obj.Levels.forEach((aux) => {
            if (aux.Level == objAux.Level) {
              if (objAux.NewValue != aux.NewValue) {
                ret = objAux.NewValue;
              }
            }
          });
          objAux.ConfirmValue = ret;
        } else {
          if (this.obj.Value == objAux.Value) {
            objAux.ConfirmValue = this.translate("lblNotChanged");
          } else {
            objAux.ConfirmValue = "";
          }
        }
      } catch (e) {
        console.log("Error in checkValues() " + e);
      }
    },

    disabledResetBtn() {
      let ret = true;
      try {
        if (this.objBackup.isMulti) {
          this.objBackup.Levels.forEach((aux) => {
            if (aux.Reset) {
              ret = false;
            }
          });
        } else {
          ret = false;
        }
      } catch (e) {
        console.log("Error in disableResetBtn() " + e);
      }

      return ret;
    },

    save() {
      try {
        this.showModalConfirmation = false;
        this.errorResetValue = false;
        this.message = "";
        let errorMegaRes = false;
        let message = "";
        if (
          parseFloat(this.objBackup.Value) <
          parseFloat(this.objBackup.IniValue) ||
          parseFloat(this.objBackup.Value) > parseFloat(this.objBackup.MaxValue)
        ) {
          errorMegaRes = true;
          message =
            this.translate("messageErrorValue") +
            " " +
            this.strMinValueMega +
            " " +
            this.translate("to") +
            " " +
            this.strMaxValueMega;
        }
        if (
          parseFloat(this.objBackup.ReserveValue) < parseFloat(0) ||
          parseFloat(this.objBackup.ReserveValue) > parseFloat(this.objBackup.ReserveMax)
        ) {
          errorMegaRes = true;
          if (message != "") {
            message += "\n ";
          }
          message +=
            this.translate("messageErrorReserveValue") +
            " " +
            0 +
            " " +
            this.translate("to") +
            " " +
            this.strMaxValueReserve;
        }
        if (errorMegaRes) {
          this.errorResetValue = true;
          this.message = message;
        }
        if (!this.errorResetValue) {
          if (
            this.objBackup.typeModule == typesModules.multi ||
            this.objBackup.typeModule == typesModules.prog
          ) {
            this.objReset = {
              IdProg: this.objBackup.ProgId,
              idMulti: this.objBackup.MultiId,
              ResetGlobal: this.objBackup.isGlobal,
              ResetMega: false,
              MegaValue: 0,
              ResetGrand: false,
              GrandValue: 0,
              ResetMajor: false,
              MajorValue: 0,
              ResetMinor: false,
              MinorValue: 0,
              ResetReserve: this.objBackup.bResetReserve,
              ReserveValue: 0,
            };
            let errorLevel = false;
            if (this.objBackup.isMulti) {
              this.objBackup.Levels.forEach((element) => {
                if (element.Level === "Grand" && element.Reset) {
                  if (
                    parseFloat(element.MaxValue) <
                    parseFloat(element.NewValue) ||
                    parseFloat(element.IniValue) > parseFloat(element.NewValue)
                  ) {
                    errorLevel = true;
                    if (message != "") {
                      message += "\n ";
                    }
                    message +=
                      this.translate("messageErrorGrandValue") +
                      " " +
                      this.strMinValueGrand +
                      " " +
                      this.translate("to") +
                      " " +
                      this.strMaxValueGrand;
                  }

                  if (!errorLevel) {
                    this.objReset.ResetGrand = true;
                    this.objReset.GrandValue = element.NewValue;
                  }
                }
                if (element.Level === "Major" && element.Reset) {
                  if (
                    parseFloat(element.MaxValue) <
                    parseFloat(element.NewValue) ||
                    parseFloat(element.IniValue) > parseFloat(element.NewValue)
                  ) {
                    errorLevel = true;
                    if (message != "") {
                      message += "\n ";
                    }
                    message +=
                      this.translate("messageErrorMajorValue") +
                      " " +
                      this.strMinValueMajor +
                      " " +
                      this.translate("to") +
                      " " +
                      this.strMaxValueMajor;
                  }

                  if (!errorLevel) {
                    this.objReset.ResetMajor = true;
                    this.objReset.MajorValue = element.NewValue;
                  }
                }
                if (element.Level === "Minor" && element.Reset) {
                  if (
                    parseFloat(element.MaxValue) <
                    parseFloat(element.NewValue) ||
                    parseFloat(element.IniValue) > parseFloat(element.NewValue)
                  ) {
                    errorLevel = true;
                    if (message != "") {
                      message += "\n ";
                    }
                    message +=
                      this.translate("messageErrorMinorValue") +
                      " " +
                      this.strMinValueMinor +
                      " " +
                      this.translate("to") +
                      " " +
                      this.strMaxValueMinor;
                  }

                  if (!errorLevel) {
                    this.objReset.ResetMinor = true;
                    this.objReset.MinorValue = element.NewValue;
                  }
                }
              });
            } else {
              this.objReset.ResetMega = true;
              this.objReset.MegaValue = this.objBackup.Value;
              this.objReset.PaidValue = this.objBackup.PaidValue;
              this.objReset.ResetType = this.resetTypeUser;

              if (this.objReset.ResetReserve) {
                this.objReset.ReserveValue = this.objBackup.ReserveValue;
              }
            }
            if (errorLevel) {
              this.errorResetValue = true;
              this.message = message;
            } else {
              this.cmd = clientCommands.ProgMultiReset.name;
              this.sendingCMDMessage =
                "Waiting to Reset [" + this.obj.Name + "]";
              this.countDown = clientCommands.ProgMultiReset.time;
              this.showLoadingCMD = true;
            }
          } else if (
            this.objBackup.typeModule == typesModules.pigBoom ||
            this.objBackup.typeModule == typesModules.mystery
          ) {
            //

            this.objReset = {
              IdBonus: 0,
              Value: 0,
              isMystery: false,
            };

            if (this.objBackup.typeModule == typesModules.pigBoom) {
              this.objReset.IdBonus = this.objBackup.PigId;
              this.objReset.Value = this.objBackup.Value;
              this.objReset.isMystery = false;
            } else {
              this.objReset.IdBonus = this.objBackup.MysteryId;
              this.objReset.Value = this.objBackup.Value;
              this.objReset.isMystery = true;
            }

            this.cmd = clientCommands.ResetBonus.name;
            this.sendingCMDMessage =
              this.translate("cmdResetBonus") + " [" + this.obj.Name + "]";
            this.countDown = clientCommands.ResetBonus.time;
            this.showLoadingCMD = true;
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    },

    afterLoading(data) {
      if (this.restartCommunication) {
        this.afterReset(data);
      } else {
        console.log("Closing ResetValue Modal");
        this.$emit("close");
      }
    },

    openKeyboardReset(value, level) {
      if (this.levelType == level) {
        this.showKeyboard = false;
        this.levelType = level;
      } else {
        this.showKeyboard = true;
        this.inputLevel = value;
        this.levelType = level;
      }
    },

    afterReset(data) {
      if (data.ok) {
        if (!this.communication) {
          this.$buefy.dialog.confirm({
            title: this.translate("dialog_Title"),
            message:
              this.translate("messageUnblockCommunication") +
              "<br/>" +
              this.translate("messageAllMachinesConnect"),
            confirmText: this.translate("btnOk"),
            type: "is-primary",
            hasIcon: true,
            onConfirm: () =>
              this.sendCMD(
                clientCommands.UnblockCommunication.name,
                this.translate("waiting") +
                " " +
                this.translate("cmdUnblockCommunication"),
                clientCommands.UnblockCommunication.time
              ),
            onCancel: () => this.$emit("close"),
          });
        } else {
          this.$emit("close");
        }
      }
    },

    addStr(str, index, stringToAdd) {
      return (
        str.substring(0, index) + stringToAdd + str.substring(index, str.length)
      );
    },

    sendCMD(cmd, message, countDown) {
      this.isAfterReset = true;
      this.cmd = cmd;
      this.route = "/components/reset/ResetValue";
      this.countDown = countDown;
      this.sendingCMDMessage = message;
      this.showLoadingCMD = true;
    },
    cancel() {
      this.$emit("close");
    },

    processShowKeyboard(type){     
      if(type === 'paidValue'){
        this.showKeyboardNewValue = false
        this.showKeyboardPaidValue = !this.showKeyboardPaidValue
      }else if(type === 'newValue'){
        this.showKeyboardNewValue = !this.showKeyboardNewValue
        this.showKeyboardPaidValue = false
      }

      this.showKeyboard = this.showKeyboardPaidValue || this.showKeyboardNewValue

    },
    openModalWarningResetMega()
    {
      let mensagem = this.translate("lblPayNoConnection") + '<br> <br>' 
          +  this.translate("messageWarningResetValuePartOne")
          + this.getCurrency() + ' ' + this.objBackup.PaidValue
          + this.translate("messageWarningResetValuePartTwo");

      this.$buefy.dialog.alert({
            type: 'is-danger',
            title: this.translate('lblWarning'),
            message: mensagem,
            confirmText: this.translate('btnOk'),
          })
    },
  },

  props: {
    route: String,
    showToken: Boolean,
    showPassword: Boolean,
    showPin: Boolean,
    local: String,
    Action: Function,
    obj: Object,
    restartCommunication: Boolean,
  },

  created() {
    this.precision = this.user.countryCode == countries.Philippines ? 0 : 2;
    const coin = returnCurrency(this.user.countryCode) + " ";
    if (!this.obj.isMulti) {
      this.strMinValueMega = JSON.parse(
        JSON.stringify(this.obj.IniValue.split(coin).join(""))
      );
      this.strMaxValueMega = JSON.parse(
        JSON.stringify(this.obj.MaxValue.split(coin).join(""))
      );
      this.obj.Value = this.obj.Value.split(coin).join("");
      this.obj.Value = this.obj.Value.split(".").join("");
      this.obj.Value = this.obj.Value.split(",").join("");
      this.obj.Value = this.addStr(
        this.obj.Value,
        this.obj.Value.length - this.precision,
        "."
      );
      this.obj.Value = parseFloat(this.obj.Value);

      this.obj.IniValue = this.obj.IniValue.split(coin).join("");
      this.obj.IniValue = this.obj.IniValue.split(".").join("");
      this.obj.IniValue = this.obj.IniValue.split(",").join("");
      this.obj.IniValue = this.addStr(
        this.obj.IniValue,
        this.obj.IniValue.length - this.precision,
        "."
      );
      this.obj.IniValue = parseFloat(this.obj.IniValue);

      this.obj.MaxValue = this.obj.MaxValue.split(coin).join("");
      this.obj.MaxValue = this.obj.MaxValue.split(".").join("");
      this.obj.MaxValue = this.obj.MaxValue.split(",").join("");
      this.obj.MaxValue = this.addStr(
        this.obj.MaxValue,
        this.obj.MaxValue.length - this.precision,
        "."
      );
      this.obj.MaxValue = parseFloat(this.obj.MaxValue);
      if (
        this.obj.typeModule != typesModules.pigBoom &&
        this.obj.typeModule != typesModules.mystery
      ) {
        this.obj.ReserveValue = this.obj.ReserveValue.split(coin).join("");
        this.obj.ReserveValue = this.obj.ReserveValue.split(".").join("");
        this.obj.ReserveValue = this.obj.ReserveValue.split(",").join("");
        this.obj.ReserveValue = this.addStr(
          this.obj.ReserveValue,
          this.obj.ReserveValue.length - this.precision,
          "."
        );
        this.obj.ReserveValue = parseFloat(this.obj.ReserveValue);
        this.strMaxValueReserve = JSON.parse(
          JSON.stringify(this.obj.ReserveMax.split(coin).join(""))
        );
        this.obj.ReserveMax = this.obj.ReserveMax.split(coin).join("");
        this.obj.ReserveMax = this.obj.ReserveMax.split(".").join("");
        this.obj.ReserveMax = this.obj.ReserveMax.split(",").join("");
        this.obj.ReserveMax = this.addStr(
          this.obj.ReserveMax,
          this.obj.ReserveMax.length - this.precision,
          "."
        );
        this.obj.ReserveMax = parseFloat(this.obj.ReserveMax);
        this.obj.ReserveValue = parseFloat(0);
      }
    } else {
      this.obj.Levels.forEach((level) => {
        if (level.Level === "Grand") {
          this.strMinValueGrand = JSON.parse(
            JSON.stringify(level.IniValue.split(coin).join(""))
          );
          this.strMaxValueGrand = JSON.parse(
            JSON.stringify(level.MaxValue.split(coin).join(""))
          );
        } else if (level.Level === "Major") {
          this.strMinValueMajor = JSON.parse(
            JSON.stringify(level.IniValue.split(coin).join(""))
          );
          this.strMaxValueMajor = JSON.parse(
            JSON.stringify(level.MaxValue.split(coin).join(""))
          );
        } else if (level.Level === "Minor") {
          this.strMinValueMinor = JSON.parse(
            JSON.stringify(level.IniValue.split(coin).join(""))
          );
          this.strMaxValueMinor = JSON.parse(
            JSON.stringify(level.MaxValue.split(coin).join(""))
          );
        }

        level.IniValue = level.IniValue.split(coin).join("");
        level.IniValue = level.IniValue.split(".").join("");
        level.IniValue = level.IniValue.split(",").join("");
        level.IniValue = this.addStr(
          level.IniValue,
          level.IniValue.length - this.precision,
          "."
        );
        level.IniValue = parseFloat(level.IniValue);
        level.MaxValue = level.MaxValue.split(coin).join("");
        level.MaxValue = level.MaxValue.split(".").join("");
        level.MaxValue = level.MaxValue.split(",").join("");
        level.MaxValue = this.addStr(
          level.MaxValue,
          level.MaxValue.length - this.precision,
          "."
        );
        level.MaxValue = parseFloat(level.MaxValue);
        level.NewValue = parseFloat(level.IniValue);
        level.Reset = false;
      });
    }
    this.obj.ConfirmValue = "";
    this.objBackup = JSON.parse(JSON.stringify(this.obj));
  },
  watch: {   
  },
};
</script>

<style></style>
